import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import navLinks from 'config/navLinks';
import style from './sidebar.module.scss';
import { ReactComponent as Logo } from 'assets/icons/sidebar-logo.svg';
import userHasPermission from 'helpers/userHasPermissions';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const { auth } = useSelector((state) => state);
  return (
    <div className={style.sidebar__wrapper}>
      <div className={style.sidebar__header}>
        <Link to="/dashboard">
          <Logo />
        </Link>
      </div>
      <div className={style.menu}>
        {navLinks.map((link, index) => {
          if (
            userHasPermission(auth?.signIn?.userPermissions, link.key, 'view') ||
            auth?.signIn?.role?.toLowerCase() === 'hq_admin'
          ) {
            if (index === 7 || index === 5 || index === 11) {
              return (
                <div key={index}>
                  <div className={style.sidebar__divider}></div>
                  <NavLink
                    to={link.url}
                    className={({ isActive }) => (isActive ? style.active : undefined)}
                  >
                    <li className={style.menu__link}>
                      <img src={link.image} alt={link.name} />
                      <span>{link.title}</span>
                    </li>
                  </NavLink>
                </div>
              );
            }
            return (
              <NavLink
                to={link.url}
                key={link.title}
                className={({ isActive }) => (isActive ? style.active : undefined)}
              >
                <li className={style.menu__link}>
                  <img src={link.image} alt={link.name} />
                  <span>{link.title}</span>
                </li>
              </NavLink>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Sidebar;
