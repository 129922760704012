const referralTypes = {
  GET_REFERRALS: 'GET_REFERRALS',
  GET_SINGLE_REFERRAL: 'GET_SINGLE_REFERRAL',
  EDIT_REFERRAL: 'EDIT_REFERRAL',
  GET_REFERRALS_PAID_TRANSACTIONS: 'GET_REFERRALS_PAID_TRANSACTIONS',
  GET_REFERRALS_PAYMENT_DETAILS: 'GET_REFERRALS_PAYMENT_DETAILS',
  GET_REFERRALS_DUE_PAYMENT: 'GET_REFERRALS_DUE_PAYMENT',
  GET_REFERRALS_DUE_PAYMENT_DETAILS: 'GET_REFERRALS_DUE_PAYMENT_DETAILS',
  GET_REFERRALS_RECORD_PAYMENT: 'GET_REFERRALS_RECORD_PAYMENT',
  DELETE_REFERRALS: 'DELETE_REFERRALS'
};

export default referralTypes;
