const packageTypes = {
  GET_ALL_PACKAGES: 'GET_ALL_PACKAGES',
  SET_ALL_PACKAGES: 'SET_ALL_PACKAGES',
  CREATE_SINGLE_PACKAGE: 'CREATE_SINGLE_PACKAGE',
  GET_PACKAGE_DETAILS: 'GET_PACKAGE_DETAILS',
  SET_PACKAGE_DETAILS: 'SET_PACKAGE_DETAILS',
  SAVE_PACKAGE_SUMMARY: 'SAVE_PACKAGE_SUMMARY',
  GET_PACKAGE_TESTS: 'GET_PACKAGE_TESTS',
  SET_PACKAGE_TESTS: 'SET_PACKAGE_TESTS',
  SAVE_SELECTED_TESTS: 'SAVE_SELECTED_TESTS',
  SAVE_NEW_TESTS: 'SAVE_NEW_TESTS',
  EDIT_PACKAGE: 'EDIT_PACKAGE',
  PACKAGE_STATUS: 'PACKAGE_STATUS',
  GET_ALL_SUPERPACKAGES: 'GET_ALL_SUPERPACKAGES',
  SET_ALL_SUPERPACKAGES: 'SET_ALL_SUPERPACKAGES',
  EDIT_SUPERPACKAGE_STATUS: 'EDIT_SUPERPACKAGE_STATUS',
  CREATE_SUPER_PACKAGE: 'CREATE_SUPER_PACKAGE',
  SAVE_SUPER_PACKAGE_DATA: 'SAVE_SUPER_PACKAGE_DATA',
  EDIT_SUPER_PACKAGE: 'EDIT_SUPER_PACKAGE',
  GET_SUPERPACKAGES_DETAILS: 'GET_SUPERPACKAGES_DETAILS',
  SET_SUPERPACKAGES_DETAILS: 'SET_SUPERPACKAGES_DETAILS',
  REMOVE_PACKAGE_FROM_SUPERPACKAGE: 'REMOVE_PACKAGE_FROM_SUPERPACKAGE',
  GET_PARTNER_PACKAGES: 'GET_PARTNER_PACKAGES'
};

export default packageTypes;
