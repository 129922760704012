const claimTypes = {
  GET_ALL_CLAIMS: 'GET_ALL_CLAIMS',
  SET_ALL_CLAIMS: 'SET_ALL_CLAIMS',
  GET_SINGLE_CLAIM_DETAILS: 'GET_SINGLE_CLAIM_DETAILS',
  SET_SINGLE_CLAIM_DETAILS: 'SET_SINGLE_CLAIM_DETAILS',
  FILE_CLAIMS: 'FILE_CLAIMS',
  MAKE_PAYMENT: 'MAKE_PAYMENT'
};

export default claimTypes;
