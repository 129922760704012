/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from '../../../centerFormLayout/centerForm.module.scss';
import Loader from 'components/Loader';
import api from 'services/api';
import Gallery from 'assets/icons/image-gallery.png';
import PropTypes from 'prop-types';

const ImageFile = ({ setImageFile, imageDisplayed, edit, displayImage, setDisplayImage }) => {
  const [loading, setLoading] = useState(false);
  const UPLOAD_ENDPOINT = 'settings/upload';
  const handleUpload = async (e) => {
    setLoading(true);
    const body = new FormData();
    if (e.target.files.length !== 0) {
      setDisplayImage(URL.createObjectURL(e?.target?.files[0]));
      body.append('file', e?.target?.files[0]);
      api
        .post(`${process.env.REACT_APP_MDAAS_BACKEND_API_URL}/v1/${UPLOAD_ENDPOINT}`, body)
        .then((res) => {
          setLoading(false);
          setDisplayImage(res?.data?.data[0]);
          setImageFile(res?.data?.data[0]);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <p style={{ fontSize: '14px', color: '#425466', marginBottom: '10px' }}>
        {edit ? 'Change Center Image' : 'Add Center Image'}
      </p>
      <div className={styles.image__wrapper}>
        {loading ? (
          <div className={styles.loader}>
            <Loader color="black" />
          </div>
        ) : (
          <label>
            {!displayImage ? (
              <div>
                <img src={Gallery} alt="image-gallery" />
                {edit ? <p>Change Center Image</p> : <p>Add Center Image</p>}
              </div>
            ) : (
              <img src={displayImage} alt="display" className={styles.displayImage} />
            )}
            <input
              id="fileInput"
              className={styles.hide}
              type="file"
              onChange={(e) => {
                handleUpload(e);
              }}
            />
          </label>
        )}
      </div>
      <br />
    </div>
  );
};
ImageFile.propTypes = {
  setImageFile: PropTypes.func,
  imageDisplayed: PropTypes.func,
  edit: PropTypes.bool
};
export default ImageFile;
