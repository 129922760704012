const profileTypes = {
  GET_PROFILE: 'GET_PROFILE',
  SET_PROFILE: 'SET_PROFILE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  INVITE_USER: 'INVITE_USER',
  SET_USER: 'SET_USER',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  GET_ALL_USER: 'GET_ALL_USER',
  SET_ALL_USER: 'SET_ALL_USER',
  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
  FETCH_HQ_USER_DETAILS: 'FETCH_HQ_USER_DETAILS',
  RESET_AUTH: 'RESET_AUTH',
  FETCH_BEAM_USERS: 'FETCH_BEAM_USERS',
  UPDATE_PARTNER_STATUS: 'UPDATE_PARTNER_STATUS'
};

export default profileTypes;
