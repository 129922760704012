import rebateTypes from './rebate.types';

export const createRebateScheduleAction = (payload, cb, cbError) => ({
  type: rebateTypes.CREATE_REBATE_SCHEDULES,
  payload,
  cb,
  cbError
});

export const getRebateScheduleAction = (payload, cb, cbError) => ({
  type: rebateTypes.GET_REBATE_SCHEDULES,
  payload,
  cb,
  cbError
});

export const getRebateScheduleDetailsAction = (payload, cb, cbError) => ({
  type: rebateTypes.GET_REBATE_SCHEDULE_DETAILS,
  payload,
  cb,
  cbError
});

export const getResponderRebatesAction = (payload, cb, cbError) => ({
  type: rebateTypes.GET_RESPONDER_REBATES,
  payload,
  cb,
  cbError
});

export const markRebateAsPaidAction = (payload, cb, cbError) => ({
  type: rebateTypes.MARK_REBATE_AS_PAID,
  payload,
  cb,
  cbError
});
