const centerTypes = {
  GET_BRANCHES: 'GET_BRANCHES',
  SET_BRANCHES: 'SET_BRANCHES',
  CREATE_BRANCH: 'CREATE_BRANCH',
  ASSIGN_ADMIN: 'ASSIGN_ADMIN',
  SET_ADMIN: 'SET_ADMIN',
  GET_SINGLE_BRANCH: 'GET_SINGLE_BRANCH',
  SET_SINGLE_BRANCH: 'SET_SINGLE_BRANCH',
  EDIT_BRANCH_DETAIL: 'EDIT_BRANCH_DETAIL',
  GET_BRANCH: 'GET_BRANCH',
  SET_BRANCH_STATUS: 'SET_BRANCH_STATUS',
  UPDATE_BRANCH_STATUS: 'UPDATE_BRANCH_STATUS',
  FETCH_BRANCH_USER_DETAILS: 'FETCH_BRANCH_USER_DETAILS',
  UPDATE_BRANCH_ADMIN: 'UPDATE_BRANCH_ADMIN',
  GET_CENTER_PROCEDURE: 'GET_CENTER_PROCEDURE',
  FETCH_CENTRE_ROLE: 'FETCH_CENTRE_ROLE',
  RE_ASSIGN_ADMIN: 'RE_ASSIGN_ADMIN',
  GET_WEBSITE_CENTERS: ' GET_WEBSITE_CENTERS',
  CREATE_WEBSITE_CENTER: 'CREATE_WEBSITE_CENTER',
  GET_WEBSITE_CENTER_DETAILS: 'GET_WEBSITE_CENTER_DETAILS',
  DELETE_WEBSITE_CENTER: ' DELETE_WEBSITE_CENTER',
  EDIT_WEBSITE_CENTER_DETAILS: 'EDIT_WEBSITE_CENTER_DETAILS'
};

export default centerTypes;
