import React from 'react';
import PropTypes from 'prop-types';
import styles from 'container/rolesManagement/roles.module.scss';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader';

const DeleteCenterModal = ({ isOpen, isClose, onDelete, loadingBtn }) => {
  return (
    <Modal isOpen={isOpen} isClose={isClose} width={464} height={200}>
      <div>
        <h2 className={styles.modal__header}>Delete Center</h2>
        <div className={styles.modal__caption}>
          <p>Are you sure you want to delete this center?</p>
        </div>
        <div className={styles.modal__button}>
          <button className={styles.modal__button_cancel} onClick={isClose}>
            Cancel
          </button>
          <button
            className={styles.modal__button_save}
            data-testid="logout-button"
            onClick={onDelete}
          >
            {loadingBtn ? <Loader color="white" /> : 'Delete'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteCenterModal.propTypes = {
  isOpen: PropTypes.func,
  isClose: PropTypes.object,
  onDelete: PropTypes.func,
  loadingBtn: PropTypes.bool
};

export default DeleteCenterModal;
