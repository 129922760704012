import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './tabs.module.scss';

const Tabs = ({ tabs, onClick, active, setActive }) => {
  const activeClass = (tab) => tab?.id === active?.id && styles.active;
  return (
    <ul className={classnames(styles.tabs)}>
      {tabs?.map((tab) => (
        <li
          key={tab.id}
          className={classnames(
            styles.tabs__item,
            tab.disabled ? styles['tabs__item-disabled'] : '',
            activeClass(tab)
          )}
        >
          <button
            type="button"
            onClick={() => {
              onClick(tab);
              setActive(tab);
            }}
            style={{ textTransform: 'capitalize' }}
          >
            {tab.label === 'dna' ? 'DNA' : tab.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  onClick: PropTypes.func,
  active: PropTypes.any,
  setActive: PropTypes.func
};

export default Tabs;
