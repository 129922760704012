import resultTrackingTypes from './result.types';

export const initialState = {
  results: [],
  multiple_results: []
};

export const resultsReducers = (state = initialState, action) => {
  switch (action.type) {
    case resultTrackingTypes.SET_ALL_RESULT_TRACKING:
      return {
        ...state,
        results: action.payload
      };

    case resultTrackingTypes.SET_MULTIPLE_RESULTS:
      return {
        ...state,
        multiple_results: action.payload
      };
    default:
      return state;
  }
};

export default resultsReducers;
