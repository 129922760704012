import { API } from 'const';
import { takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import supportTypes from './support.types';
import queryFormatter from 'helpers/queryFormatter';

function* getAllTickets(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.SUPPORT}?${queryFormatter(data.params)}`));
    if (res.status === 200 || res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getTicketsByStatus(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.SUPPORT}?${queryFormatter(data.params)}`));
    if (res.status === 200 || res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getSingleTickets(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.SUPPORT}/${data.id}`));
    if (res.status === 200 || res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* updateTicketStatus(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.SUPPORT}/${data.id}`, data.payload));
    if (res.status === 200 || res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* createMessage(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.SUPPORT}/send-message/${data.id}`, data.payload));
    if (res.status === 200 || res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* watchSupportSaga() {
  yield takeEvery(supportTypes.GET_ALL_TICKETS, getAllTickets);
  yield takeEvery(supportTypes.GET_TICKETS_BY_STATUS, getTicketsByStatus);
  yield takeEvery(supportTypes.GET_SINGLE_TICKETS, getSingleTickets);
  yield takeEvery(supportTypes.UPDATE_TICKET_STATUS, updateTicketStatus);
  yield takeEvery(supportTypes.CREATE_MESSAGE, createMessage);
}

export default watchSupportSaga;
