/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import styles from './table.module.scss';
import TableHeader from '../Table/TableHeader';
import TableBody from '../Table/TableBody';
import PropTypes from 'prop-types';

export const TableLoader = ({ headers }) => (
  <div className={styles.skeleton}>
    <div className={styles.skeleton__head} />
    {headers?.slice(0, 4).map((_, index) => (
      <div key={index} className={styles.skeleton__row}>
        {headers?.map((header, idx) => (
          <div key={idx} className={styles.skeleton__item} />
        ))}
      </div>
    ))}
  </div>
);

TableLoader.propTypes = {
  headers: PropTypes.array
};

const Table = ({
  headers,
  tableData,
  children,
  placeHolderImg,
  loading,
  placeholderText,
  onCheck,
  disableHeaderCheck
}) => {
  const sortRows = () => true;

  const table = (
    <div>
      <table className={`${styles.table}`}>
        <TableHeader
          sortRows={sortRows}
          tableHeaders={headers}
          onCheck={(e) => onCheck(e)}
          disableHeaderCheck={disableHeaderCheck}
        />
        <TableBody
          cols={headers?.length}
          tableData={tableData}
          content={children}
          placeHolderImg={placeHolderImg}
          placeholderText={placeholderText}
        />
      </table>
    </div>
  );
  return loading ? <TableLoader headers={headers} /> : table;
};

Table.defaultProps = {
  currentPage: 1,
  totalPage: 10,
  changeCurrentPage: null,
  forcePage: false,
  loading: false
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.any),
  sortRows: PropTypes.any,
  placeHolderImg: PropTypes.node,
  tableData: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.any,
  cols: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  placeholderText: PropTypes.string,
  disableHeaderCheck: PropTypes.bool
};
export default Table;
