import React from 'react';
import PropTypes from 'prop-types';

const Text = (props) => {
  const { children, as, ...rest } = props;
  const Textcomponent = as || 'span';

  return <Textcomponent {...rest}>{children}</Textcomponent>;
};

Text.defaultProps =
  {
    children: React.ReactNode,
    as: 'C'
  } & React.ComponentPropsWithoutRef && 'C';

Text.propTypes = {
  children: PropTypes.string,
  as: PropTypes.string
};

export default Text;
