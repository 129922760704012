import React from 'react';
import PropTypes from 'prop-types';
import styles from 'container/rolesManagement/roles.module.scss';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader';

const DeletePackagesModal = ({ isOpen, isClose, onDelete, loadingBtn, superpackage, remove }) => {
  return (
    <Modal isOpen={isOpen} isClose={isClose} width={464} height={200}>
      <div>
        <h2 className={styles.modal__header}>
          {remove ? 'Remove' : 'Delete'} {superpackage ? 'Super-package' : 'Package'}
        </h2>
        <div className={styles.modal__caption}>
          <p>
            Are you sure you want to {remove ? 'remove' : 'delete'} this{' '}
            {superpackage ? 'super-package' : 'package'}?
          </p>
        </div>
        <div className={styles.modal__button}>
          <button className={styles.modal__button_cancel} onClick={isClose}>
            Cancel
          </button>
          <button
            className={styles.modal__button_save}
            data-testid="logout-button"
            onClick={onDelete}
          >
            {loadingBtn ? <Loader color="white" /> : remove ? 'Remove' : 'Delete'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeletePackagesModal.propTypes = {
  isOpen: PropTypes.func,
  isClose: PropTypes.object,
  onDelete: PropTypes.func,
  loadingBtn: PropTypes.bool,
  superpackage: PropTypes.bool,
  remove: PropTypes.bool
};

export default DeletePackagesModal;
