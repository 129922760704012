/**
 * @name: useLoading
 * @description: A useHook that renders the loading state
 */

import { useState } from 'react';

const useLoaderState = () => {
  const [loading, setLoading] = useState(false);
  return { loading, setLoading };
};

export default useLoaderState;
