import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.scss';

const Checkbox = ({ id, name, onClick, checked, value, onChange, disabled }) => (
  <label className={styles.container}>
    <input
      id={id}
      type="checkbox"
      name={name}
      onClick={onClick}
      checked={checked}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
    <span className={styles.checkmark} />
  </label>
);

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.any
};

export default Checkbox;
