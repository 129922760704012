import dashboardTypes from './dashboard.types';

export const initialState = {
  dashboardCardAnalytics: {},
  patientVisitTrend: []
};

export const dashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case dashboardTypes.SET_DASHBOARDCARD_ANALYTICS:
      return {
        ...state,
        dashboardCardAnalytics: action.payload
      };
    case dashboardTypes.SET_PATIENT_VISIT:
      return {
        ...state,
        patientVisitTrend: action.payload
      };
    default:
      return state;
  }
};

export default dashboardReducers;
