import { API } from 'const';
import { takeEvery, call } from 'redux-saga/effects';
import queryFormatter from 'helpers/queryFormatter';
import referralTypes from './referral.types';
import api from 'services/api';

export function* getreferrals(action) {
  try {
    const { payload: data, cb } = action;
    // const res = yield call(() => api.get(`${API.REFEREE}?${queryFormatter(data.params)}`));
    const res = yield call(() => api.get(`${API.REFEREE}?${queryFormatter(data?.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSingleReferral(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.REFEREE}/${data.id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editReferral(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.put(`${API.REFEREE}/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getReferralPaidTransactions(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.REFEREE}/${data.id}/paid-transactions?${queryFormatter(data?.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getReferralPaymentDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.REFEREE}/${data.id}/transaction-details`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getReferralDuePayment(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.REFEREE}/${data.id}/transactions?${queryFormatter(data?.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getReferralDuePaymentDetails(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.REFEREE}/${data.referralID}/${data.paymentID}/transaction`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getReferralRecordPayment(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.REFEREE}/pay-referee/${data.id}`, data.data));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message;
    cbError(alert);
  }
}

export function* deleteSingleReferral(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.delete(`${API.REFEREE}/delete/${data?.id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchReferralSaga() {
  yield takeEvery(referralTypes.GET_REFERRALS, getreferrals);
  yield takeEvery(referralTypes.GET_SINGLE_REFERRAL, getSingleReferral);
  yield takeEvery(referralTypes.EDIT_REFERRAL, editReferral);
  yield takeEvery(referralTypes.GET_REFERRALS_PAID_TRANSACTIONS, getReferralPaidTransactions);
  yield takeEvery(referralTypes.GET_REFERRALS_PAYMENT_DETAILS, getReferralPaymentDetails);
  yield takeEvery(referralTypes.GET_REFERRALS_DUE_PAYMENT, getReferralDuePayment);
  yield takeEvery(referralTypes.GET_REFERRALS_DUE_PAYMENT_DETAILS, getReferralDuePaymentDetails);
  yield takeEvery(referralTypes.GET_REFERRALS_RECORD_PAYMENT, getReferralRecordPayment);
  yield takeEvery(referralTypes.DELETE_REFERRALS, deleteSingleReferral);
}

export default watchReferralSaga;
