import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const CreateRolePage = lazy(() => import('container/rolesManagement/createRole'));
export const PermissionsPage = lazy(() => import('container/rolesManagement/permissions'));
export const EditRolePage = lazy(() => import('container/rolesManagement/editRole'));
export const ViewPermissionPage = lazy(() => import('container/rolesManagement/viewPermissions'));
export const RolePage = lazy(() => import('pages/Role/Role'));

export default function RoleRoutes() {
  return (
    <Routes>
      <Route
        path="/create-role"
        element={
          <PrivateRoute>
            <CreateRolePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/permissions/:type"
        element={
          <PrivateRoute>
            <PermissionsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/permissions/:id"
        element={
          <PrivateRoute>
            <PermissionsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-role/:id/:page"
        element={
          <PrivateRoute>
            <EditRolePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/view-permission/:id/:type/:page"
        element={
          <PrivateRoute>
            <ViewPermissionPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/role-management"
        element={
          <PrivateRoute>
            <RolePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
