import actionTypes from './auth.types';

export const doSignInAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_SIGNIN,
  payload,
  cb,
  cbError
});

export const doSendPasswordEmailAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_SEND_EMAIL,
  payload,
  cb,
  cbError
});

export const doValidateOtpAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_VALIDATE_OTP,
  payload,
  cb,
  cbError
});

export const doSetPasswordAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_SET_PASSWORD,
  payload,
  cb,
  cbError
});

export const doSetNewPasswordAction = (payload, cb, cbError) => ({
  type: actionTypes.DO_SET_NEW_PASSWORD,
  payload,
  cb,
  cbError
});

export const getRefreshTokenAction = (payload, cb, cbError) => ({
  type: actionTypes.GET_REFRESH_TOKEN,
  payload,
  cb,
  cbError
});

export const doSignOutAction = (payload, cb, cbError) => ({
  DocumentType: actionTypes.RESET_AUTH,
  payload,
  cb,
  cbError
});
