import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './input.module.scss';
import PropTypes from 'prop-types';

const ReactPhoneInput = ({ value, onChange, error, country, ...rest }) => {
  return (
    <div>
      <label htmlFor="phone" className={styles.input__label}>
        Phone Number
      </label>
      <PhoneInput
        inputProps={{
          name: 'phone'
        }}
        value={value}
        onChange={onChange}
        buttonClass={styles.pi_button}
        inputClass={styles.pi_input}
        dropdownClass={styles.pi_dropdown}
        containerClass="pi-container"
        country={country}
        enableAreaCodes={true}
        autoFormat={true}
        {...rest}
        inputStyle={{ width: '100%', height: '56px', border: '1px solid #c1c6d3' }}
      />
    </div>
  );
};
ReactPhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.any,
  error: PropTypes.string,
  country: PropTypes.any
};
export default ReactPhoneInput;
