import procedureTypes from './procedure.types';

export const getAllProcedureAction = (payload, cb, cbError) => ({
  type: procedureTypes.GET_ALL_PROCEDURE,
  payload,
  cb,
  cbError
});

export const createProcedureTypeAction = (payload, cb, cbError) => ({
  type: procedureTypes.CREATE_PROCEDURE_TYPE,
  payload,
  cb,
  cbError
});

export const getProcedureCategoriesAction = (payload, cb, cbError) => ({
  type: procedureTypes.GET_PROCEDURE_CATEGORIES,
  payload,
  cb,
  cbError
});

export const createProcedureCategoriesAction = (payload, cb, cbError) => ({
  type: procedureTypes.CREATE_PROCEDURE_CATEGORIES,
  payload,
  cb,
  cbError
});

export const getProcedureTypeCategoriesAction = (payload, cb, cbError) => ({
  type: procedureTypes.GET_PROCEDURETYPE_CATEGORIES,
  payload,
  cb,
  cbError
});

export const getCategoryTestAction = (payload, cb, cbError) => ({
  type: procedureTypes.GET_CATEGORY_TEST,
  payload,
  cb,
  cbError
});

export const createProcedureTestAction = (payload, cb, cbError) => ({
  type: procedureTypes.CREATE_PROCEDURE_TEST,
  payload,
  cb,
  cbError
});

export const createPriceTypeAction = (payload, cb, cbError) => ({
  type: procedureTypes.CREATE_PRICE_TYPE,
  payload,
  cb,
  cbError
});

export const getPriceTypesAction = (payload, cb, cbError) => ({
  type: procedureTypes.GET_PRICE_TYPES,
  payload,
  cb,
  cbError
});

export const editTestAction = (payload, cb, cbError) => ({
  type: procedureTypes.EDIT_TEST_TYPES,
  payload,
  cb,
  cbError
});

export const getUngroupedTestAction = (payload, cb, cbError) => ({
  type: procedureTypes.GET_UNGROUPED_TEST,
  payload,

  cb,
  cbError
});

export const deleteSingleTestAction = (payload, cb, cbError) => ({
  type: procedureTypes.DELETE_TEST,
  payload,
  cb,
  cbError
});

export const doUpdateTestsAction = (payload, cb, cbError) => ({
  type: procedureTypes.DO_UPDATE_TESTS,
  payload,
  cb,
  cbError
});

export const doUploadCheckerProcedureAction = (payload, cb, cbError) => ({
  type: procedureTypes.DO_CHECKER_PROCEDURE,
  payload,
  cb,
  cbError
});
