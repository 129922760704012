import React from 'react';
import styles from './table.module.scss';
import PropTypes from 'prop-types';
import placeholder from '../../assets/icons/table-placeholder.svg';

const TableBody = ({ tableData, content, cols, placeHolderImg, placeholderText }) => (
  <tbody className="table__body">
    {!tableData?.length && (
      <tr className={styles.table__row}>
        <td className="" colSpan={cols}>
          <div className={styles.table__empty}>
            <div>
              {placeHolderImg || (
                <>
                  <img src={placeholder} alt="" />
                  <p>No {placeholderText} found</p>
                </>
              )}
            </div>
          </div>
        </td>
      </tr>
    )}
    {tableData?.map((row, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <tr className={styles.table__row} key={index}>
        <>{content(row)}</>
      </tr>
    ))}
  </tbody>
);
TableBody.defaultProps = {
  placeHolderImg: null
};

TableBody.propTypes = {
  placeHolderImg: PropTypes.node,
  tableData: PropTypes.arrayOf(PropTypes.any),
  content: PropTypes.func,
  cols: PropTypes.number,
  placeholderText: PropTypes.string
};

export default TableBody;
