import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const AuditTrailPage = lazy(() => import('pages/AuditTrail/AuditTrail'));
export const AuditDetailsPage = lazy(() => import('container/auditTrail/auditDetails'));
export const ViewLogsPage = lazy(() => import('container/auditTrail/viewLogs'));

export default function AuditRoutes() {
  return (
    <Routes>
      <Route
        path="audit/trail"
        element={
          <PrivateRoute>
            <AuditTrailPage />
          </PrivateRoute>
        }
      />
      <Route
        path="audit/trail/details/:id/:page"
        element={
          <PrivateRoute>
            <AuditDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/trail/view/logs/:id/:page"
        element={
          <PrivateRoute>
            <ViewLogsPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
