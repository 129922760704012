import RightSideModal from 'components/Modal/RightSideModal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../websiteManagement.module.scss';
import Button from 'components/Button/Button';
import RadioButton from 'components/RadioButton';

const FilterHomePackages = ({ isOpen, isClose, setFilteredData, setPage }) => {
  const [categoryType, setCategoryType] = useState('');
  const handleFilter = () => {
    setFilteredData({
      show_in: categoryType
    });
    isClose();
    setPage(1);
  };
  return (
    <RightSideModal isOpen={isOpen} isClose={isClose}>
      <div>
        <div className={styles.filterModal__header}>
          <h2>Filter</h2>
          <button
            className={styles.filterModal__header__cancelButton}
            onClick={() => {
              isClose();
              setPage(1);
            }}
          >
            X
          </button>{' '}
        </div>
        {/* <p className={styles.filterModal__title}>Date Range</p> */}
        {/* <div className={styles.filterModal__dateRange}>
          <DatePickerInput
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="dd mm yyyy"
          />
          <DatePickerInput
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="dd mm yyyy"
          />
        </div> */}

        <div style={{ marginTop: '32px' }}>
          <p className={styles.filterModal__title}>Category type</p>

          <RadioButton
            text="Homepage (blue frame)"
            value="Homepage (blue frame)"
            name="category_type"
            onChange={(e) => setCategoryType(e.target.value)}
          />
          <RadioButton
            text="Centres page"
            value="Centres page"
            name="category_type"
            onChange={(e) => setCategoryType(e.target.value)}
          />
          <RadioButton
            text="Packages page"
            value="Packages page"
            name="category_type"
            onChange={(e) => setCategoryType(e.target.value)}
          />
        </div>

        <div className={styles.filterModal__button}>
          <Button size="sm" type="submit" theme="oblige" onClick={() => isClose()}>
            Cancel
          </Button>
          <Button size="sm" type="submit" theme="primary" onClick={() => handleFilter()}>
            Filter
          </Button>
        </div>
      </div>
    </RightSideModal>
  );
};

FilterHomePackages.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.object,
  setFilteredData: PropTypes.any,
  setPage: PropTypes.func
};
export default FilterHomePackages;
