import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const OnlineBookingsPage = lazy(() => import('pages/OnlineBookings/OnlineBookings'));
export const PatientBookingDetailsPage = lazy(() =>
  import('container/OnlineBookings/patientBookingDetails')
);

export default function OnlineBookingsRoutes() {
  return (
    <Routes>
      <Route
        path="/online-bookings"
        element={
          <PrivateRoute>
            <OnlineBookingsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/online-bookings/:id/:payer/:page"
        element={
          <PrivateRoute>
            <PatientBookingDetailsPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
