import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import financialTypes from './financial.types';
import queryFormatter from 'helpers/queryFormatter';

function* getCardAnalytic(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.ANALYTICS}?day_offset=${data.params}`));
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_CARD_ANALYTICS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getCentersCollectedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/transactions?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_CENTERS_COLLECTED_REVENUES, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
function* getCentersBookedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/booked-transactions?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_CENTERS_BOOKED_REVENUES, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getChannelsCollectedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(
        `${API.ANALYTICS}/transaction-breakdown?branch_id=${data?.id}&${queryFormatter(
          data.params
        )}`
      )
    );
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_CHANNELS_COLLECTED_REVENUES, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getChannelsBookedRevenue(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(
        `${API.ANALYTICS}/booked-transaction-breakdown?branch_id=${data?.id}&${queryFormatter(
          data.params
        )}`
      )
    );
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_CHANNELS_BOOKED_REVENUES, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getGraphTrend(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/transaction-trends?term=${data?.params}`)
    );
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_GRAPH_TREND, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getCollectedRevenueTopLine(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/revenue-analytics?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_COLLECTED_REVENUE_TOPLINE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getBookedRevenueTopLine(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/booked-revenue-analytics?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: financialTypes.SET_BOOKED_REVENUE_TOPLINE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getChannelsCollectedRevenueDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(
        `${API.ANALYTICS}/revenue-details?branch_id=${data?.id}&${queryFormatter(data.params)}`
      )
    );
    if (res.status === 200) {
      yield put({
        type: financialTypes.SET_CHANNEL_COLLECTED_REVENUES_DETAIL,
        payload: res.data.data
      });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
function* getChannelsBookedRevenueDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.ANALYTICS}/booked-revenue-details?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({
        type: financialTypes.SET_CHANNEL_BOOKED_REVENUES_DETAIL,
        payload: res.data.data
      });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
function* watchFinancialSaga() {
  yield takeEvery(financialTypes.GET_CARD_ANALYTICS, getCardAnalytic);
  yield takeEvery(financialTypes.GET_CENTERS_COLLECTED_REVENUES, getCentersCollectedRevenue);
  yield takeEvery(financialTypes.GET_CENTERS_BOOKED_REVENUES, getCentersBookedRevenue);
  yield takeEvery(financialTypes.GET_CHANNELS_COLLECTED_REVENUES, getChannelsCollectedRevenue);
  yield takeEvery(financialTypes.GET_CHANNELS_BOOKED_REVENUES, getChannelsBookedRevenue);
  yield takeEvery(financialTypes.GET_GRAPH_TREND, getGraphTrend);
  yield takeEvery(financialTypes.GET_COLLECTED_REVENUE_TOPLINE, getCollectedRevenueTopLine);
  yield takeEvery(financialTypes.GET_BOOKED_REVENUE_TOPLINE, getBookedRevenueTopLine);
  yield takeEvery(
    financialTypes.GET_CHANNEL_COLLECTED_REVENUES_DETAIL,
    getChannelsCollectedRevenueDetails
  );
  yield takeEvery(
    financialTypes.GET_CHANNEL_BOOKED_REVENUES_DETAIL,
    getChannelsBookedRevenueDetails
  );
}

export default watchFinancialSaga;
