import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SinglePageLoader from 'components/Loading/SinglePageLoader';
import styles from '../websiteManagement.module.scss';
import { getWebsiteCenterDetailsAction } from 're-ducks/centers';
import Modal from 'components/Modal/Modal';
import PropTypes from 'prop-types';
import Close from 'assets/icons/close.svg';

const WebsiteCenterDetails = ({ id, isOpen, isClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [websiteCenterDetails, setWebsiteCenterDetails] = useState([]);
  useEffect(() => {
    setLoading(true);
    dispatch(
      getWebsiteCenterDetailsAction(id, (res) => {
        setWebsiteCenterDetails(res);
        setLoading(false);
      })
    );
  }, [dispatch, id]);

  return (
    <Modal isOpen={isOpen} isClose={isClose} width={616} height={200} radius={16}>
      {loading ? (
        <SinglePageLoader />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <img
              src={Close}
              alt="close"
              onClick={(e) => {
                e.stopPropagation();
                isClose();
              }}
            />
          </div>
          <div className={styles.centerDetails}>
            <h6>Centre name</h6>
            {websiteCenterDetails.center_type === 'Full Service' ? (
              <h3>{websiteCenterDetails.center_name || '-'}</h3>
            ) : (
              <h3>{websiteCenterDetails.name || '-'}</h3>
            )}

            <div>
              <h6>Centre type</h6>
              <h2
                className={
                  websiteCenterDetails.center_type === 'Full Service'
                    ? styles.centerDetails__fullservice
                    : styles.centerDetails__affiliate
                }
              >
                {websiteCenterDetails.center_type || '-'}
              </h2>
            </div>
            <h6>Address & contact</h6>
            <h4 style={{ paddingBottom: '0' }}>{websiteCenterDetails.address || '-'}</h4>
            <h4 style={{ marginTop: '8px' }}>{websiteCenterDetails.contact_number || '-'}</h4>

            <div style={{ marginTop: '35px' }}>
              <div>
                <h6>Opening hours</h6>
                <div className={styles.centerDetails__hours}>
                  <h4>Weekdays</h4>
                  <div className={styles.centerDetails__time}>
                    <h4>{websiteCenterDetails.time_open_weekday}</h4>
                    <h4>{websiteCenterDetails.time_closed_weekday}</h4>
                  </div>
                </div>
              </div>
              <div className={styles.centerDetails__hours}>
                <h4>Saturdays</h4>
                <div className={styles.centerDetails__time}>
                  <h4>{websiteCenterDetails.time_open_weekend}</h4>
                  <h4>{websiteCenterDetails.time_closed_weekend}</h4>
                </div>
              </div>
            </div>
            <div>
              <h4>Centre photos</h4>
              <img
                src={websiteCenterDetails.image}
                alt="Center Image"
                style={{ width: '100%', borderRadius: '12px' }}
              />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
WebsiteCenterDetails.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  isClose: PropTypes.func
};
export default WebsiteCenterDetails;
