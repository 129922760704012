/* eslint-disable react/prop-types */
import SinglePageLoader from 'components/Loading/SinglePageLoader';
import React, { useCallback, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import styles from '../websiteManagement.module.scss';
import { GripVertical } from 'lucide-react';
import SingleCategory from './singleCategory';
import ViewCategoryModal from '../packageManagement/viewCategoryModal';
import DeleteCategory from './deleteCategory';
import { deleteWebsiteCategoryOrSubCategoryAction } from 're-ducks/websiteManagement';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ResponsiveGridLayout = WidthProvider(Responsive);

const ResponsiveHomePackages = ({
  loading,
  allCategories,
  setUpdatedData,
  loader,
  setLoader,
  fetchAllWebsiteCategories,
  toggleOrderPackages
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const CARD_HEIGHT = toggleOrderPackages === true ? 170 : 140;

  const generateLayout = useCallback((items) => {
    return items.map((item, index) => ({
      i: item.id.toString(),
      x: index % 4,
      y: Math.floor(index / 4),
      w: 1,
      h: 1 // Keep this as 1, we'll control actual height via rowHeight
    }));
  }, []);
  const layout = useMemo(
    () => generateLayout(allCategories.packageCategories),
    [allCategories.packageCategories, generateLayout]
  );

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragStop = useCallback(() => {
    setIsDragging(false);
  }, []);

  const deleteCategory = useCallback(() => {
    setLoader(true);
    dispatch(
      deleteWebsiteCategoryOrSubCategoryAction(
        {
          delete: 'category',
          category_id: currentItem?.id
        },
        () => {
          setLoader(false);
          toast.success('Category has been deleted');
          setCurrentItem({});
          setDeleteModal(false);
          fetchAllWebsiteCategories();
        },
        (err) => {
          setLoader(false);
          toast.error(err);
          setDeleteModal(false);
        }
      )
    );
  }, [currentItem?.id, dispatch, fetchAllWebsiteCategories]);

  const onLayoutChange = useCallback(
    (newLayout) => {
      if (!isDragging) return;

      const updatedOrder = newLayout
        .sort((a, b) => (a.y === b.y ? a.x - b.x : a.y - b.y))
        .map((item, index) => ({
          order: index + 1,
          category_id: item.i,
          show_in: allCategories?.packageCategories
            .find((cat) => cat.id.toString() === item.i)
            ?.show_in?.join(',')
        }));
      setUpdatedData(updatedOrder);
    },
    [isDragging, allCategories.packageCategories]
  );
  return (
    <>
      {loading ? (
        <SinglePageLoader />
      ) : (
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 4, md: 4, sm: 2, xs: 1, xxs: 1 }}
          rowHeight={CARD_HEIGHT} // Set rowHeight to our constant
          onLayoutChange={onLayoutChange}
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          isDraggable
          isResizable={false}
          draggableHandle=".drag-handle"
          containerPadding={[5, 5]}
          margin={[20, 20]}
        >
          {allCategories.packageCategories.map((item) => (
            <div
              key={item.id.toString()}
              className={styles.gridItem}
              style={{ height: CARD_HEIGHT }}
            >
              {toggleOrderPackages === true ? (
                <div className={`${styles.dragHandle} drag-handle`}>
                  <GripVertical size={20} />
                </div>
              ) : null}
              <SingleCategory
                onDelete={() => {
                  setCurrentItem(item);
                  setDeleteModal(true);
                }}
                item={item}
                onCategoryView={() => {
                  toggleOrderPackages === false ? setViewModal(true) : setViewModal(false);
                  setCurrentItem(item);
                }}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
      )}
      {viewModal && (
        <ViewCategoryModal
          isOpen={viewModal}
          isClose={() => setViewModal(false)}
          currentItem={currentItem}
        />
      )}
      <DeleteCategory
        isOpen={deleteModal}
        isClose={() => setDeleteModal(false)}
        onDelete={deleteCategory}
        loadingBtn={loader}
      />
    </>
  );
};

export default ResponsiveHomePackages;
