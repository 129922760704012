import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import dashboardTypes from './dashboard.types';

function* getDashboardCardAnalytic(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(`${API.ANALYTICS}/hq-dashboard-cards`));
    if (res.status === 200) {
      yield put({ type: dashboardTypes.SET_DASHBOARDCARD_ANALYTICS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* gePatientVisitTrend(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.ANALYTICS}/visit-trends?year=${data.params}`));
    if (res.status === 200) {
      yield put({ type: dashboardTypes.SET_PATIENT_VISIT, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
function* watchDashboardSaga() {
  yield takeEvery(dashboardTypes.GET_DASHBOARDCARD_ANALYTICS, getDashboardCardAnalytic);
  yield takeEvery(dashboardTypes.GET_PATIENT_VISIT, gePatientVisitTrend);
}

export default watchDashboardSaga;
