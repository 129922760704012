import Loader from 'components/Loader';
import Modal from 'components/Modal/Modal';
import styles from 'container/rolesManagement/roles.module.scss';
import PropTypes from 'prop-types';

const DeleteCategory = ({ isOpen, isClose, onDelete, loadingBtn }) => {
  return (
    <Modal width={464} height={200} isOpen={isOpen} isClose={isClose}>
      <div>
        <h2 className={styles.modal__header}>Delete Category</h2>
        <div className={styles.modal__caption}>
          <p>Are you sure you want to delete this category?</p>
        </div>
        <div className={styles.modal__button}>
          <button className={styles.modal__button_cancel} onClick={isClose}>
            Cancel
          </button>
          <button className={styles.modal__button_save} onClick={onDelete}>
            {loadingBtn ? <Loader color="white" /> : 'Delete'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteCategory.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.func,
  onDelete: PropTypes.func,
  loadingBtn: PropTypes.bool
};

export default DeleteCategory;
