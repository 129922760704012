import claimTypes from './claims.types';

export const initialState = {
  claims: [],
  singleClaim: {}
};

export const claimReducers = (state = initialState, action) => {
  switch (action.type) {
    case claimTypes.SET_ALL_CLAIMS:
      return {
        ...state,
        claims: action.payload
      };
    case claimTypes.SET_SINGLE_CLAIM_DETAILS:
      return {
        ...state,
        singleClaim: action.payload
      };
    default:
      return state;
  }
};

export default claimReducers;
