import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import actionTypes from './user.types';
import queryFormatter from 'helpers/queryFormatter';

export function* getProfile(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(`${API.USER_PROFILE}`));
    if (res.status === 200) {
      yield put({ type: actionTypes.SET_PROFILE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editProfile(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.USER_PROFILE}`, data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* addUser(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.USER}`, data));
    if (res.status === 201) {
      yield put({ type: actionTypes.SET_USER, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updatePassword(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.UPDATE_USER_PASSWORD}`, data));
    if (res.status === 200) {
      cb();
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getAllUsers(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.USER}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: actionTypes.SET_ALL_USER, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updateuserStatus(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.USER}/${data?.id}/status`, data?.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchHqUserDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.USER}/hq/${data}`, data?.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* fetchBeamUsers(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.PARTNERS}/panel-users?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updatePartnerStatus(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.PARTNERS}/status/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchProfileSaga() {
  yield takeEvery(actionTypes.GET_PROFILE, getProfile);
  yield takeEvery(actionTypes.EDIT_PROFILE, editProfile);
  yield takeEvery(actionTypes.INVITE_USER, addUser);
  yield takeEvery(actionTypes.UPDATE_PASSWORD, updatePassword);
  yield takeEvery(actionTypes.GET_ALL_USER, getAllUsers);
  yield takeEvery(actionTypes.UPDATE_USER_STATUS, updateuserStatus);
  yield takeEvery(actionTypes.FETCH_HQ_USER_DETAILS, fetchHqUserDetails);
  yield takeEvery(actionTypes.FETCH_BEAM_USERS, fetchBeamUsers);
  yield takeEvery(actionTypes.UPDATE_PARTNER_STATUS, updatePartnerStatus);
}

export default watchProfileSaga;
