import profileTypes from './user.types';

export const getProfileAction = (cb, cbError) => ({
  type: profileTypes.GET_PROFILE,
  cb,
  cbError
});

export const editProfileAction = (payload, cb, cbError) => ({
  type: profileTypes.EDIT_PROFILE,
  payload,
  cb,
  cbError
});

export const addUserAction = (payload, cb, cbError) => ({
  type: profileTypes.INVITE_USER,
  payload,
  cb,
  cbError
});

export const updatePasswordAction = (payload, cb, cbError) => ({
  type: profileTypes.UPDATE_PASSWORD,
  payload,
  cb,
  cbError
});

export const getAllUsersAction = (payload, cb, cbError) => ({
  type: profileTypes.GET_ALL_USER,
  payload,
  cb,
  cbError
});

export const updateuserStatusAction = (payload, cb, cbError) => ({
  type: profileTypes.UPDATE_USER_STATUS,
  payload,
  cb,
  cbError
});

export const fetchUserHqDetailsAction = (payload, cb, cbError) => ({
  type: profileTypes.FETCH_HQ_USER_DETAILS,
  payload,
  cb,
  cbError
});

export const fetchBeamUsersAction = (payload, cb, cbError) => ({
  type: profileTypes.FETCH_BEAM_USERS,
  payload,
  cb,
  cbError
});

export const updatePartnerStatusAction = (payload, cb, cbError) => ({
  type: profileTypes.UPDATE_PARTNER_STATUS,
  payload,
  cb,
  cbError
});
