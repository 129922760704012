import patientTypes from './patient.types';

export const getAllPatientActions = (payload, cb, cbError) => ({
  type: patientTypes.GET_PATIENTS,
  payload,
  cb,
  cbError
});

export const getSinglePatientAction = (payload, cb, cbError) => ({
  type: patientTypes.GET_SINGLE_PATIENT,
  payload,
  cb,
  cbError
});

export const deleteSinglePatientAction = (payload, cb, cbError) => ({
  type: patientTypes.DELETE_PATIENT,
  payload,
  cb,
  cbError
});
