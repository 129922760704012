import React, { useEffect, useState } from 'react';
import CenterFormLayout from 'components/Layout/centerFormLayout';
import styles from '../packages.module.scss';
import ReactSelect from 'components/Select/ReactSelect';
import FormInput from 'components/FormInput/FormInput';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EditPackagesToSuperPackageModal from './editPackagesToSuperPackageModal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getSuperPackageDetailsAction } from 're-ducks/packages/packages.action';
import { getAllPartnersAction } from 're-ducks/partner';
import { useNavigate, useParams } from 'react-router-dom';

const EditSuperPackageModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [openEditPackagesToSuperPackage, setEditPackagesToSuperPackage] = useState(false);
  const [selectPartnershipType, setSelectedPartnershipType] = useState({
    label: '',
    value: ''
  });
  const [selectPayingPartner, setSeletedPayingPartner] = useState({
    label: '',
    value: ''
  });
  const [packageDetails, setpackageDetails] = useState([]);
  const [payingPartner, setPayingPartner] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getSuperPackageDetailsAction(
        {
          id: params?.id
        },
        (res) => {
          setLoading(false);
          setData(res?.metaData);
          setSelectedPartnershipType({
            label: res?.metaData?.partnership_type,
            value: res?.metaData?.partnership_type
          });
          setSeletedPayingPartner({
            label: res?.metaData?.paying_partner,
            value: res?.metaData?.paying_partner
          });
          setpackageDetails(res?.packages);
        },
        (err) => {
          setLoading(false);
          toast.error(err);
        }
      )
    );
  }, []);

  const onBackClick = () => {
    dispatch({
      type: 'SAVE_SUPER_PACKAGE_DATA',
      payload: {}
    });
    return navigate('/packages?type=superpackages');
  };

  useEffect(() => {
    dispatch(
      getAllPartnersAction(
        {
          params: {
            type: selectPartnershipType?.value?.toLowerCase()
          }
        },
        (res) => {
          setPayingPartner(res?.partners?.filter((i) => i.name === selectPayingPartner?.value)[0]);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, [selectPartnershipType]);

  const formik = useFormik({
    initialValues: {
      name: data && data?.name
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Package name is required')
    }),
    onSubmit: (values) => {
      dispatch({
        type: 'SAVE_SUPER_PACKAGE_DATA',
        payload: {
          name: values?.name,
          packageDetails: packageDetails,
          paying_partner: selectPayingPartner,
          partnership_type: selectPartnershipType?.value
        }
      });
      navigate(`/superpackage/edit/procedures/${params?.id}`);
    },
    enableReinitialize: true
  });

  return (
    <div>
      <CenterFormLayout
        backSize="sm"
        onBackClick={() => onBackClick()}
        onClose={() => onBackClick()}
        closeTheme="tertiary"
        closeSize="sm"
        title="Edit Super Package"
      >
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className={styles.packages_create__details}>
              <div className={styles.packages_create__details_form}>
                <div>
                  <FormInput
                    label="Super Package Name"
                    placeholder="Enter Package Name"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    id="name"
                  />
                  {formik.errors.name ? <span className="error">{formik.errors.name}</span> : null}
                </div>
                <ReactSelect
                  label="Partnership Type"
                  loading={loading}
                  placeholder="Select..."
                  isSearchable
                  onChange={() => {}}
                  options={[]}
                  name="partnershipType"
                  value={selectPartnershipType}
                  isDisabled
                  blurText
                />
                <div>
                  <ReactSelect
                    label="Paying Partner"
                    placeholder="Select..."
                    loading={loading}
                    isSearchable
                    onChange={() => {}}
                    options={[]}
                    name="payingPartner"
                    value={selectPayingPartner}
                    isDisabled
                    blurText
                  />
                </div>
                <div>
                  <Button
                    theme="primary"
                    size="md"
                    loading={loading}
                    disabled={!formik.isValid || formik?.values?.name?.length === 0}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </CenterFormLayout>{' '}
      {/* {openEditPackagesToSuperPackage && (
        <EditPackagesToSuperPackageModal
          isOpen={openEditPackagesToSuperPackage}
          isClose={() => setEditPackagesToSuperPackage(false)}
          closeEditSuperPackageModal={() => isClose()}
          rowId={rowId}
        />
      )} */}
    </div>
  );
};
EditSuperPackageModal.propTypes = {
  isOpen: PropTypes.any,
  isClose: PropTypes.any,
  rowId: PropTypes.string
};
export default EditSuperPackageModal;
