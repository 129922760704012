import packageTypes from './packages.types';

export const initialState = {
  packages: [],
  package_details: [],
  summary: {},
  procedures: [],
  saved_tests: [],
  superpackages: [],
  super_package_data: {},
  superpackage_details: {}
};

export const packageReducers = (state = initialState, action) => {
  switch (action.type) {
    case packageTypes.SET_ALL_PACKAGES:
      return {
        ...state,
        packages: action.payload
      };
    case packageTypes.SET_PACKAGE_DETAILS:
      return {
        ...state,
        package_details: action.payload
      };
    case packageTypes.SAVE_PACKAGE_SUMMARY:
      return {
        ...state,
        summary: action.payload
      };
    case packageTypes.SET_PACKAGE_TESTS:
      return {
        ...state,
        procedures: action.payload
      };
    case packageTypes.SAVE_SELECTED_TESTS:
      return {
        ...state,
        saved_tests: action.payload
      };
    case packageTypes.SAVE_NEW_TESTS:
      return {
        ...state,
        new_tests: action.payload
      };
    case packageTypes.SET_ALL_SUPERPACKAGES:
      return {
        ...state,
        superpackages: action.payload
      };
    case packageTypes.SAVE_SUPER_PACKAGE_DATA:
      return {
        ...state,
        super_package_data: action.payload
      };
    case packageTypes.SET_SUPERPACKAGES_DETAILS:
      return {
        ...state,
        superpackage_details: action.payload
      };
    default:
      return state;
  }
};

export default packageReducers;
