import financialTypes from './financial.types';

export const getCardAnalyticAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_CARD_ANALYTICS,
  payload,
  cb,
  cbError
});

export const getCentersCollectedRevenueAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_CENTERS_COLLECTED_REVENUES,
  payload,
  cb,
  cbError
});

export const getCentersBookedRevenueAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_CENTERS_BOOKED_REVENUES,
  payload,
  cb,
  cbError
});

export const getChannelsCollectedRevenueAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_CHANNELS_COLLECTED_REVENUES,
  payload,
  cb,
  cbError
});

export const getChannelsBookedRevenueAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_CHANNELS_BOOKED_REVENUES,
  payload,
  cb,
  cbError
});

export const getGraphTrendAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_GRAPH_TREND,
  payload,
  cb,
  cbError
});

export const getCollectedRevenueTopLineAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_COLLECTED_REVENUE_TOPLINE,
  payload,
  cb,
  cbError
});

export const getBookedRevenueTopLineAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_BOOKED_REVENUE_TOPLINE,
  payload,
  cb,
  cbError
});
export const getChannelCollectedDetailAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_CHANNEL_COLLECTED_REVENUES_DETAIL,
  payload,
  cb,
  cbError
});

export const getChannelBookedDetailAction = (payload, cb, cbError) => ({
  type: financialTypes.GET_CHANNEL_BOOKED_REVENUES_DETAIL,
  payload,
  cb,
  cbError
});
