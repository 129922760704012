import React from 'react';
import Select from 'react-select';
import styles from './select.module.scss';
import PropTypes from 'prop-types';

const ReactSelect = ({
  placeholder,
  loading,
  isSearchable,
  onChange,
  options,
  name,
  value,
  isClearable,
  label,
  menuIsOpen,
  defaultValue,
  isMulti,
  isDisabled,
  closeMenuOnSelect,
  blurText,
  height
}) => {
  return (
    <div>
      <p className={blurText ? styles.select__blurColor : styles.select__reactSelectLabel}>
        {label}
      </p>
      <Select
        placeholder={placeholder}
        isLoading={loading}
        isSearchable={isSearchable}
        onChange={onChange}
        options={options}
        name={name}
        value={value}
        isMulti={isMulti}
        isClearable={isClearable}
        menuIsOpen={menuIsOpen}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        closeMenuOnSelect={closeMenuOnSelect}
        maxMenuHeight={height}
      />
    </div>
  );
};
ReactSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  loading: PropTypes.bool,
  value: PropTypes.object,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  defaultValue: PropTypes.object,
  closeMenuOnSelect: PropTypes.bool,
  isDisabled: PropTypes.any,
  isMulti: PropTypes.bool,
  blurText: PropTypes.bool,
  height: PropTypes.any
};
export default ReactSelect;
