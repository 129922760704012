import websiteManagementTypes from './websiteManagement.types';

export const createWebHomePackagesAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.CREATE_HOMEPAGE_CATEGORIES,
  payload,
  cb,
  cbError
});

export const createWebHomeSubCategoriesAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.CREATE_HOMEPAGE_SUBCATEGORIES,
  payload,
  cb,
  cbError
});

export const addWebsiteProceduresAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.ADD_WEBSITE_PROCEDURES,
  payload,
  cb,
  cbError
});

export const curatePackagesAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.CURATE_PACKAGES,
  payload,
  cb,
  cbError
});

export const deleteSubCategoryProcedureAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.DELETE_SUBCATEGORY_PROCEDURE,
  payload,
  cb,
  cbError
});

export const fetchCuratedPackagesAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.FETCH_CURATED_PACKAGES,
  payload,
  cb,
  cbError
});

export const fetchAllWebsiteCategoriesAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.FETCH_ALL_CATEGORIES,
  payload,
  cb,
  cbError
});

export const fetchWebsiteCategoryDetailsAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.FETCH_CATEGORY_DETAILS,
  payload,
  cb,
  cbError
});

export const fetchWebsiteSubCategoryDetailsAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.FETCH_SUBCATEGORY_DETAILS,
  payload,
  cb,
  cbError
});

export const deleteWebsiteCategoryOrSubCategoryAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.DELETE_CATEGORY_OR_SUBCATEGORY,
  payload,
  cb,
  cbError
});

export const editWebsiteCategoriesAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.EDIT_WEBSITE_CATEGORIES,
  payload,
  cb,
  cbError
});

export const orderCategoryAction = (payload, cb, cbError) => ({
  type: websiteManagementTypes.ORDER_CATEGORIES,
  payload,
  cb,
  cbError
});
