import supportTypes from './support.types';

export const getAllTicketsAction = (payload, cb, cbError) => ({
  type: supportTypes.GET_ALL_TICKETS,
  payload,
  cb,
  cbError
});

export const getTicketsByStatusAction = (payload, cb, cbError) => ({
  type: supportTypes.GET_TICKETS_BY_STATUS,
  payload,
  cb,
  cbError
});

export const getSingleTicketsAction = (payload, cb, cbError) => ({
  type: supportTypes.GET_SINGLE_TICKETS,
  payload,
  cb,
  cbError
});

export const updateTicketStatusAction = (payload, cb, cbError) => ({
  type: supportTypes.UPDATE_TICKET_STATUS,
  payload,
  cb,
  cbError
});

export const createMessageAction = (payload, cb, cbError) => ({
  type: supportTypes.CREATE_MESSAGE,
  payload,
  cb,
  cbError
});
