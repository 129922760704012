const rebateTypes = {
  CREATE_REBATE_SCHEDULES: 'CREATE_REBATE_SCHEDULES',
  GET_REBATE_SCHEDULES: 'GET_REBATE_SCHEDULES',
  SET_REBATE_SCHEDULES: 'SET_REBATE_SCHEDULES',
  GET_REBATE_SCHEDULE_DETAILS: 'GET_REBATE_SCHEDULE_DETAILS',
  GET_RESPONDER_REBATES: 'GET_RESPONDER_REBATES',
  MARK_REBATE_AS_PAID: 'MARK_REBATE_AS_PAID'
};

export default rebateTypes;
