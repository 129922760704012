import Dropdown from 'components/Dropdown/Dropdown';
import styles from '../websiteManagement.module.scss';
import Dots from 'assets/icons/vertical-dot.svg';
import { Option } from 'components/Dropdown/Option';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { handleUpdateName } from 'helpers/nameChange';
import { useState } from 'react';
import WebsiteCenterDetails from './WebsiteCenterDetails';

const SingleCenter = ({ handleDeleteCenter, item }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={styles.single_category} onClick={() => setIsModalOpen(true)}>
      <div className={styles.single_category__caption}>
        <div className={styles.single_category__caption__category}>
          <h6 className={styles.single_category__caption__category_name}>{item?.name}</h6>
          <p className={styles.single_category__caption__category_nametitle}>Center name</p>
        </div>
        <p className={styles.single_category__view} onClick={(e) => e.stopPropagation()}>
          <Dropdown
            content={
              <div>
                <Option onClick={() => setIsModalOpen(true)}>View</Option>

                <Option onClick={() => navigate(`/edit-website-center/${item.id}`)}>
                  <p>Edit</p>
                </Option>
                <Option onClick={() => handleDeleteCenter()}>
                  <p>Delete</p>
                </Option>
              </div>
            }
          >
            <img src={Dots} alt="dot" />
          </Dropdown>
        </p>
      </div>

      <div className={styles.single_category__type}>
        <div style={{ display: 'flex', gap: '16px' }}>
          <div>
            <p
              className={
                item?.center_type === 'Affiliate'
                  ? styles.single_category__type_affiliate
                  : styles.single_category__type_p
              }
            >
              {handleUpdateName(item?.center_type)}
            </p>
            <p className={styles.single_category__type_nametitle}>Center type</p>
          </div>
          <div>
            <p className={styles.single_category__type_count}>{item.state}</p>
            <p className={styles.single_category__type_nametitle}>Location</p>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <WebsiteCenterDetails id={item.id} isOpen={isModalOpen} isClose={handleClose} />
      )}
    </div>
  );
};

SingleCenter.propTypes = {
  handleDeleteCenter: PropTypes.func,
  item: PropTypes?.any
};

export default SingleCenter;
