import referralTypes from './referral.types';

export const getreferralsActions = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFERRALS,
  payload,
  cb,
  cbError
});

export const getSingleReferralActions = (payload, cb, cbError) => ({
  type: referralTypes.GET_SINGLE_REFERRAL,
  payload,
  cb,
  cbError
});

export const editReferralActions = (payload, cb, cbError) => ({
  type: referralTypes.EDIT_REFERRAL,
  payload,
  cb,
  cbError
});

export const getReferralPaidTransactionsActions = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFERRALS_PAID_TRANSACTIONS,
  payload,
  cb,
  cbError
});

export const getReferralPaymentDetailsActions = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFERRALS_PAYMENT_DETAILS,
  payload,
  cb,
  cbError
});

export const getReferralDuePaymentActions = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFERRALS_DUE_PAYMENT,
  payload,
  cb,
  cbError
});

export const getReferralDuePaymentDetailsActions = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFERRALS_DUE_PAYMENT_DETAILS,
  payload,
  cb,
  cbError
});

export const getReferralRecordPaymentActions = (payload, cb, cbError) => ({
  type: referralTypes.GET_REFERRALS_RECORD_PAYMENT,
  payload,
  cb,
  cbError
});

export const deleteSingleReferralAction = (payload, cb, cbError) => ({
  type: referralTypes.DELETE_REFERRALS,
  payload,
  cb,
  cbError
});
