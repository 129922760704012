import Table from 'components/Table';
import React, { useState } from 'react';
import TableData from '../tableData';
import Indicator from 'components/indicator';
import Dropdown from 'components/Dropdown/Dropdown';
import { Option } from 'components/Dropdown/Option';
import Dots from 'assets/icons/vertical-dot.svg';
import classNames from 'classnames';
import styles from '../../packages.module.scss';
import PropTypes from 'prop-types';
import DeletePackagesModal from 'container/packages/deletePackage';
import { useNavigate, useParams } from 'react-router-dom';
import StatusModal from 'container/packages/allPackages/StatusModal';
import { useDispatch } from 'react-redux';
import { editPackageStatusAction } from 're-ducks/packages';
import { toast } from 'react-toastify';
import {
  getSuperPackageDetailsAction,
  removePackageFromSuperPackageAction
} from 're-ducks/packages/packages.action';
import { FormatDateTime } from 'helpers/formatDateTime';

const SuperPackagesDetailsTable = ({ headers, tableData, loading, page }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const params = useParams();
  const [rowStatus, setRowStatus] = useState({});

  const handleStatusModal = () => {
    const status = rowStatus?.status === 'active' ? 'deactivated' : 'active';
    setStatusLoader(true);
    dispatch(
      editPackageStatusAction(
        {
          id: rowStatus.id,
          params: {
            status: status
          }
        },
        (res) => {
          toast.success(res.message);
          setStatusModal(false);
          setStatusLoader(false);

          dispatch(
            getSuperPackageDetailsAction(
              {
                id: params.id
              },
              () => {},
              () => {}
            )
          );
        },
        (err) => {
          toast.error(err);
          setStatusLoader(false);
          setStatusModal(false);
        }
      )
    );
  };

  const handleDeleteStatus = () => {
    setDeleteLoader(true);
    dispatch(
      removePackageFromSuperPackageAction(
        {
          id: rowStatus?.id,
          params: {
            status: 'deleted'
          }
        },
        (res) => {
          setDeleteLoader(false);
          toast.success(res.message);
          setDeleteModal(false);

          dispatch(
            getSuperPackageDetailsAction(
              {
                id: params.id
              },
              () => {},
              () => {}
            )
          );
        },
        (err) => {
          setDeleteLoader(false);
          toast.error(err);
          setDeleteModal(false);
        }
      )
    );
  };

  return (
    <div>
      <Table headers={headers} tableData={tableData} loading={loading} placeholderText="packages">
        {(row) => {
          return (
            <>
              <TableData row={row} page={page} params={params}>
                {row?.name?.length > 22 ? `${row?.name?.slice(0, 22)}...` : row.name}
              </TableData>
              <TableData row={row} page={page} params={params}>
                {row.package_type}
              </TableData>
              <TableData
                row={row}
                page={page}
                params={params}
                route={true}
                className={classNames('capitalize', styles.indicator)}
              >
                <Indicator isActive={row?.status} /> <p>{row?.status}</p>
              </TableData>
              <TableData row={row} page={page} params={params}>
                {FormatDateTime(row?.created_at)}
              </TableData>
              <td>
                <Dropdown
                  content={
                    <div>
                      <Option
                        onClick={() =>
                          navigate(
                            `/packages/details/${row.id}/${page}?filter=${JSON.stringify({
                              type: 'superpackage',
                              page: page,
                              superid: params?.id
                            })}`
                          )
                        }
                      >
                        <p>View Package</p>
                      </Option>
                      <Option
                        onClick={() => {
                          setRowStatus(row);
                          setStatusModal(true);
                        }}
                      >
                        <p>{row.status === 'active' ? 'Deactivate' : 'Activate'}</p>
                      </Option>

                      <Option
                        onClick={() => {
                          setDeleteModal(true);
                          setRowStatus(row);
                        }}
                      >
                        <p>Remove</p>
                      </Option>
                    </div>
                  }
                >
                  <img src={Dots} alt="dot" />
                </Dropdown>
              </td>
            </>
          );
        }}
      </Table>

      {statusModal && (
        <StatusModal
          isOpen
          statusCaption={rowStatus?.status}
          isClose={() => setStatusModal(false)}
          onSave={() => handleStatusModal()}
          loadingBtn={statusLoader}
        />
      )}
      {deleteModal && (
        <DeletePackagesModal
          isOpen={deleteModal}
          isClose={() => setDeleteModal(false)}
          package
          remove
          onDelete={() => handleDeleteStatus()}
          loadingBtn={deleteLoader}
        />
      )}
    </div>
  );
};
SuperPackagesDetailsTable.propTypes = {
  headers: PropTypes.array,
  tableData: PropTypes.array,
  page: PropTypes.number,
  loading: PropTypes.bool,
  filteredData: PropTypes.object
};
export default SuperPackagesDetailsTable;
