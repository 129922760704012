import React, { lazy } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

export const LoginPage = lazy(() => import('pages/Auth/Login/Login'));
export const ForgotPasswordPage = lazy(() => import('pages/Auth/PasswordRecovery/ForgotPassword'));
export const OtpPage = lazy(() => import('pages/Auth/PasswordRecovery/Otp'));
export const ResetPasswordPage = lazy(() => import('pages/Auth/PasswordRecovery/ResetPassword'));
export const ChangePasswordPage = lazy(() =>
  import('pages/Auth/ChangeUserPassword/ChangePassword')
);
export const SetPasswordPage = lazy(() => import('pages/Auth/PasswordRecovery/ResetPassword'));

export default function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth/sign-in" />} />
      <Route path="/auth/sign-in" element={<LoginPage />} />
      <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/otp" element={<OtpPage />} />
      <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
      <Route path="/auth/change-password" element={<ChangePasswordPage />} />
      <Route path="/auth/set-password" element={<SetPasswordPage />} />
    </Routes>
  );
}
