const actionTypes = {
  DO_SIGNIN: 'DO_SIGNIN',
  SET_SIGNIN_DATA: 'SET_SIGNIN_DATA',
  DO_SEND_EMAIL: 'DO_SEND_EMAIL',
  DO_VALIDATE_OTP: 'DO_VALIDATE_OTP',
  DO_SET_PASSWORD: 'DO_SET_PASSWORD',
  DO_SET_NEW_PASSWORD: 'DO_SET_NEW_PASSWORD',
  GET_REFRESH_TOKEN: 'GET_REFRESH_TOKEN',
  RESET_AUTH: 'RESET_AUTH'
};

export default actionTypes;
