import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const ProfilePage = lazy(() => import('pages/Profile/Profile'));
export const NotificationDetailsPage = lazy(() =>
  import('pages/Notifications/NotificationDetails')
);
export const InviteUserPage = lazy(() => import('container/Centers/CreateCenters/InviteUser'));
export const EditHqUserRolePage = lazy(() =>
  import('container/Centers/CenterDetails/EditHqUserRole')
);

export default function ProfileRoutes() {
  return (
    <Routes>
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <NotificationDetailsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/add-user/:id"
        element={
          <PrivateRoute>
            <InviteUserPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/hq/:id/edit-role"
        element={
          <PrivateRoute>
            <EditHqUserRolePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
