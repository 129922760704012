import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import WebsiteManagementPage from 'pages/WebsiteManagement';
import EditHomePackagesCategories from 'container/websiteManagement/homePagePackages/editHomePackagesCategories';
import ViewHomePackage from 'container/websiteManagement/homePagePackages/viewHomePackage';
import ViewCuratedPackage from 'container/websiteManagement/curatedPackages/viewCuratedPackages';
import CreateCategory from 'container/websiteManagement/packageManagement/createCategory/createCategory';
import EditCategory from 'container/websiteManagement/packageManagement/editCategory/editCategory';

export default function WebsiteManagementRoutes() {
  return (
    <Routes>
      <Route
        path="/website-management"
        element={
          <PrivateRoute>
            <WebsiteManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/website-management/home-package/create"
        element={
          <PrivateRoute>
            <CreateCategory />
          </PrivateRoute>
        }
      />
      <Route
        path="/website-management/home-package/edit/:id"
        element={
          <PrivateRoute>
            <EditCategory />
          </PrivateRoute>
        }
      />
      <Route
        path="/website-management/view/:id"
        element={
          <PrivateRoute>
            <ViewHomePackage />
          </PrivateRoute>
        }
      />
      <Route
        path="/website-management/sub-category/view/:id"
        element={
          <PrivateRoute>
            <ViewCuratedPackage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
