import onlineBookingTypes from './booking.types';

export const initialState = {
  bookings: []
};

export const onlineBookingsReducers = (state = initialState, action) => {
  switch (action.type) {
    case onlineBookingTypes.GET_ALL_ONLINE_BOOKING:
      return {
        ...state,
        bookings: action.payload
      };
    case onlineBookingTypes.GET_HOME_COLLECTION_LOGISTICS:
      return {
        ...state,
        logisticsBookings: action.payload
      };

    default:
      return state;
  }
};

export default onlineBookingsReducers;
