import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import {
  AdminRoutes,
  AuditRoutes,
  AuthRoutes,
  CenterRoutes,
  PackageRoutes,
  PartnerRoutes,
  PatientRoutes,
  ProcedureRoutes,
  ProfileRoutes,
  ReferralRoutes,
  ReportRoutes,
  ResultTrackingRoutes,
  RoleRoutes,
  SupportRoutes,
  ClaimRoutes,
  RebateRoutes,
  OnlineBookingsRoutes
} from './Pages';
import WebsiteManagementRoutes from './Pages/WebsiteManagement';

export const HomePage = lazy(() => import('pages/Home/HomePage'));
const AppRouter = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <AuthRoutes />
        <CenterRoutes />
        <AuditRoutes />
        <ProcedureRoutes />
        <PartnerRoutes />
        <PatientRoutes />
        <PackageRoutes />
        <ReferralRoutes />
        <ReportRoutes />
        <AdminRoutes />
        <ProfileRoutes />
        <RoleRoutes />
        <SupportRoutes />
        <ClaimRoutes />
        <ResultTrackingRoutes />
        <RebateRoutes />
        <OnlineBookingsRoutes />
        <WebsiteManagementRoutes />
        <Routes>
          <Route
            index
            path="/dashboard"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRouter;
