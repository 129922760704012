import DashboardLayout from 'container/layouts/dashboard';
import WebsiteManagement from 'container/websiteManagement';

const WebsiteManagementPage = () => {
  return (
    <DashboardLayout>
      <WebsiteManagement />
    </DashboardLayout>
  );
};

export default WebsiteManagementPage;
