import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import queryFormatter from 'helpers/queryFormatter';
import api from 'services/api';
import resultTrackingTypes from './result.types';

export function* getAllResultTracking(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/results-tracking?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: resultTrackingTypes.SET_ALL_RESULT_TRACKING, payload: res.data.data });

      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* getSingleResultTracking(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/${data?.patient_id}/result-tracking/${data?.visit_id}`, data)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getMultipleResults(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PATIENT}/results/multiple?${data.params}`));
    if (res.status === 200) {
      yield put({ type: resultTrackingTypes.SET_MULTIPLE_RESULTS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updateResultTracking(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.put(`${API.PATIENT}/results-tracking`, data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* completePatientResult(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.PATIENT}/records`, data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchResultTrackingSaga() {
  yield takeEvery(resultTrackingTypes.GET_ALL_RESULT_TRACKING, getAllResultTracking);
  yield takeEvery(resultTrackingTypes.GET_SINGLE_RESULT_TRACKING, getSingleResultTracking);
  yield takeEvery(resultTrackingTypes.GET_MULTIPLE_RESULTS, getMultipleResults);
  yield takeEvery(resultTrackingTypes.UPDATE_RESULT_TRACKING, updateResultTracking);
  yield takeEvery(resultTrackingTypes.COMPLETE_PATIENT_RESULT, completePatientResult);
}

export default watchResultTrackingSaga;
