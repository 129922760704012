import jwtDecode from 'jwt-decode';
import axios from '../services/axios';

export const isAuthenticated = () => {
  const accessToken = localStorage.getItem('accessToken');
  // const resetToken = localStorage.getItem('resetToken');

  if (accessToken) {
    try {
      const token = jwtDecode(accessToken);
      const time = Date.now().valueOf() / 1000;
      const $5minsBefore = time - 1000 * 60 * 1;
      if (token.exp < time) {
        return false;
      }
    } catch (error) {
      return false;
    }
    axios.defaults.headers.common.Authorization = accessToken;

    return !!accessToken;
  }
  return false;
};
