import CenterFormLayout from 'components/Layout/centerFormLayout';
import WebsiteCenterForm from 'container/centerFormLayout/websiteCenterForm';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateWebsiteCenter = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    return navigate(-1);
  };

  return (
    <div>
      <CenterFormLayout
        backTheme="tertiary"
        backSize="sm"
        closeTheme="tertiary"
        closeSize="sm"
        title="Create Website Center"
        caption="Enter center details"
        onClose={handleClose}
      >
        <WebsiteCenterForm />
      </CenterFormLayout>
    </div>
  );
};

export default CreateWebsiteCenter;
