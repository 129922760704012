import React, { useState, useEffect } from 'react';
import Plus from 'assets/icons/Plus.svg';
import Button from 'components/Button/Button';
import SinglePageLoader from 'components/Loading/SinglePageLoader';
import styles from '../../packages.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Arrow from 'assets/icons/arrow-image.svg';
import DashboardLayout from 'container/layouts/dashboard';
import FormatDate from 'helpers/formatDate';
import { superPackageHeaderDetails } from 'mocks/packages';
import SuperPackagesDetailsTable from './superPackagesDetailsTable';
import EditSuperPackageModal from '../editSuperPackageModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSuperPackageDetailsAction } from 're-ducks/packages/packages.action';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination/Pagination';
import getTotalPages from 'helpers/getTotalPages';
import useLocationPath from 'hooks/useLocationPath';

const SuperPackageDetails = () => {
  const location = useLocation();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(location?.state ? location?.state?.page : 1);
  const [rowData, setRowData] = useState({});

  const params = useParams();

  const { packages } = useSelector((state) => state);
  useLocationPath({
    setPage: setPage,
    pathname: location.pathname
  });

  useEffect(() => {
    setLoading(true);
    dispatch(
      getSuperPackageDetailsAction(
        {
          id: params.id,
          params: {
            page: page,
            perPage: 50
          }
        },
        (res) => {
          setRowData(res?.metaData);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error(err);
        }
      )
    );
  }, [params.id, page]);
  const packageInfo =
    packages?.superpackage_details?.metaData && packages?.superpackage_details?.metaData;
  const { search } = useLocation();
  const filteredResponse = new URLSearchParams(search).get('filter');

  const handlePageChange = (num) => {
    setPage(num.selected + 1);
  };

  return (
    <DashboardLayout>
      {loading ? (
        <SinglePageLoader />
      ) : (
        <div className="actionBox">
          <div>
            <div className={styles.superpackages_create__heading}>
              <img
                src={Arrow}
                alt="arrow"
                onClick={() =>
                  navigate('/packages?type=superpackages', {
                    state: {
                      page: JSON.parse(filteredResponse)?.page,
                      filter: JSON.parse(filteredResponse)?.filterData
                    }
                  })
                }
                data-testid="backBtn"
              />
              <h1>Super Package Details</h1>
            </div>
          </div>
          <div className={styles.superpackages_actionBox}>
            <p>{packages?.superpackage_details?.total} Packages</p>
            <Button
              theme="primary"
              size="sm"
              dataTestId="createBtn"
              onClick={() => navigate(`/superpackage/edit/${params.id}`)}
            >
              {'Edit Super Package'}
            </Button>
          </div>
          <div>
            <div className={styles.superpackages_view_details}>
              <div>
                <p className={styles.superpackages_summary_details__heading}>Super Package Name</p>
                <p className={styles.packages_summary_details__text}>{packageInfo?.name || '-'}</p>
              </div>
              <div>
                <p className={styles.superpackages_summary_details__heading}>Partnership Type</p>
                <p className={styles.packages_summary_details__text}>
                  {packageInfo?.partnership_type || '-'}
                </p>
              </div>
              <div>
                <p className={styles.superpackages_summary_details__heading}>Paying Partner</p>
                <p className={styles.packages_summary_details__text}>
                  {packageInfo?.paying_partner || '-'}
                </p>
              </div>

              <div>
                <p className={styles.superpackages_summary_details__heading}>Date Created</p>
                <p className={styles.packages_summary_details__text}>
                  {FormatDate(packageInfo?.created_at) || '-'}
                </p>
              </div>
              <div>
                <p className={styles.superpackages_summary_details__heading}>Superpackage ID</p>
                <p className={styles.packages_summary_details__text}>
                  {packageInfo?.unique_code || '-'}
                </p>
              </div>
            </div>
          </div>
          {
            <SuperPackagesDetailsTable
              headers={superPackageHeaderDetails}
              tableData={packages?.superpackage_details?.packages}
              loading={loading}
              page={page}
            />
          }
          {!loading && (
            <Pagination
              totalPage={getTotalPages(packages?.superpackage_details?.total, 50)}
              currentPage={page}
              handlePageInput={(e) => setPage(e)}
              changeCurrentPage={handlePageChange}
              forcePage={page - 1}
            />
          )}
          {/* {editModal && (
            <EditSuperPackageModal
              isOpen={editModal}
              isClose={() => setEditModal(false)}
              rowId={rowData?.id}
              superpackage
            />
          )} */}
        </div>
      )}
    </DashboardLayout>
  );
};

export default SuperPackageDetails;
