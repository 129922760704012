import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { removeEmpty } from 'utils/removeEmptyValues';

const TableData = ({ children, row, className, route, page, filteredData, params }) => {
  const navigate = useNavigate();
  return (
    <td
      onClick={() => {
        route
          ? navigate(
              `/packages/superpackage-details/${row.id}?filter=${JSON.stringify({
                filterData: removeEmpty(filteredData),
                page: page
              })}`
            )
          : navigate(
              `/packages/details/${row.id}/${page}?filter=${JSON.stringify({
                type: 'superpackage',
                page: page,
                superid: params?.id
              })}`
            );
      }}
      className={className}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </td>
  );
};

TableData.propTypes = {
  children: PropTypes.node,
  row: PropTypes.object,
  className: PropTypes.string,
  route: PropTypes.func,
  filteredData: PropTypes.object,
  page: PropTypes.number,
  params: PropTypes.object
};

export default TableData;
