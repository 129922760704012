export const handleNameChange = (values) => {
  if (values === 'Embedded') {
    return 'embed';
  } else if (values === 'Spike') {
    return 'spike';
  } else if (values === 'Full Service') {
    return 'full_service';
  }
  return values;
};

export const handleUpdateName = (values) => {
  if (values === 'embed') {
    return 'Embedded';
  } else if (values === 'spike') {
    return 'Spike';
  } else if (values === 'full_service') {
    return 'Full Service';
  } else if (values === 'HQ_admin') {
    return 'HQ Admin';
  } else if (values === 'hq_admin') {
    return 'HQ Admin';
  }
  return values;
};
export const handleName = (values) => {
  if (values === 'Affiliate') {
    return 'Affiliate';
  } else if (values === 'Spike') {
    return 'Spike';
  } else if (values === 'embed') {
    return 'Embed';
  } else if (values === 'full_service') {
    return 'Full Service';
  }
  return values;
};
