import DashboardLayout from 'container/layouts/dashboard';
import Arrow from 'assets/icons/arrow-image.svg';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../websiteManagement.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchWebsiteSubCategoryDetailsAction } from 're-ducks/websiteManagement';
import CardList from 'components/CardList/CardList';
import SinglePageLoader from 'components/Loading/SinglePageLoader';
import EmptyState from '../emptyState';

const ViewCuratedPackage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams?.get('name');

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchWebsiteSubCategoryDetailsAction(
        { id: params?.id },
        (res) => {
          setLoading(false);
          setData(res?.data);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, []);

  return (
    <DashboardLayout>
      <div>
        <div className={styles.websiteManagement__view__heading}>
          <div>
            <img src={Arrow} onClick={() => navigate(-1)} />
            <h2>{name}</h2>
          </div>
        </div>
        {loading ? (
          <SinglePageLoader />
        ) : (
          <div>
            <div>
              <h2 className={styles.websiteManagement__heading}>Description</h2>
              <p style={{ color: '#425466', wordBreak: 'break-word' }}>
                {data?.description || 'No description available'}
              </p>
            </div>
            <div>
              <h2 className={styles.websiteManagement__view__procedureHeading}>Procedures</h2>
              <div className={styles.websiteManagement__view__procedures}>
                {data?.procedures?.map((item) => {
                  return (
                    <div className={styles.websiteManagement__view__procedures__card}>
                      <CardList title={item.procedure_name}>{item.procedure_unique_code}</CardList>
                    </div>
                  );
                })}
              </div>
              {data?.procedures?.length === 0 ? <EmptyState /> : null}
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ViewCuratedPackage;
