const reportTypes = {
  CREATE_REPORT: 'CREATE_REPORT',
  FETCH_REPORTS: 'FETCH_REPORTS',
  SET_REPORTS: 'SET_REPORTS',
  FETCH_SINGLE_REPORT: 'FETCH_SINGLE_REPORT',
  SET_SINGLE_REPORT: 'SET_SINGLE_REPORT',
  FETCH_TESTS: 'FETCH_TESTS',
  SET_TESTS: 'SET_TESTS',
  EDIT_TESTS: 'EDIT_TESTS',
  DELETE_SINGLE_REPORT: 'DELETE_SINGLE_REPORT',
  SAVE_PROCEDURE: 'SAVE_PROCEDURE'
};

export default reportTypes;
