import centerTypes from './centers.types';

export const getBranchesAction = (payload, cb, cbError) => ({
  type: centerTypes.GET_BRANCHES,
  payload,
  cb,
  cbError
});

export const createBranchAction = (payload, cb, cbError) => ({
  type: centerTypes.CREATE_BRANCH,
  payload,
  cb,
  cbError
});

export const assignAdminAction = (id, payload, cb, cbError) => ({
  type: centerTypes.ASSIGN_ADMIN,
  id,
  payload,
  cb,
  cbError
});

export const getSingleBranchAction = (id, cb, cbError) => ({
  type: centerTypes.GET_SINGLE_BRANCH,
  id,
  cb,
  cbError
});

export const editBranchAction = (payload, cb, cbError) => ({
  type: centerTypes.EDIT_BRANCH_DETAIL,
  payload,
  cb,
  cbError
});

export const updateBranchAction = (id, payload, cb, cbError) => ({
  type: centerTypes.UPDATE_BRANCH_STATUS,
  id,
  payload,
  cb,
  cbError
});

export const fetchBranchUserDetailsAction = (branch_id, id, cb, cbError) => ({
  type: centerTypes.FETCH_BRANCH_USER_DETAILS,
  branch_id,
  id,
  cb,
  cbError
});

export const updateBranchAdminAction = (payload, cb, cbError) => ({
  type: centerTypes.UPDATE_BRANCH_ADMIN,
  payload,
  cb,
  cbError
});

export const getCenterProcedureAction = (payload, cb, cbError) => ({
  type: centerTypes.GET_CENTER_PROCEDURE,
  payload,
  cb,
  cbError
});

export const fetchCentreRolesAction = (payload, cb, cbError) => ({
  type: centerTypes.FETCH_CENTRE_ROLE,
  payload,
  cb,
  cbError
});

export const reassignAdminAction = (payload, cb, cbError) => ({
  type: centerTypes.RE_ASSIGN_ADMIN,
  payload,
  cb,
  cbError
});
export const getWebsiteCentersAction = (payload, cb, cbError) => ({
  type: centerTypes.GET_WEBSITE_CENTERS,
  payload,
  cb,
  cbError
});
export const createWebsiteCentersAction = (payload, cb, cbError) => ({
  type: centerTypes.CREATE_WEBSITE_CENTER,
  payload,
  cb,
  cbError
});
export const getWebsiteCenterDetailsAction = (id, cb, cbError) => ({
  type: centerTypes.GET_WEBSITE_CENTER_DETAILS,
  id,
  cb,
  cbError
});
export const deleteWebCenterAction = (id, cb, cbError) => ({
  type: centerTypes.DELETE_WEBSITE_CENTER,
  id,
  cb,
  cbError
});
export const editWebsiteCenterAction = (id, payload, cb, cbError) => {
  return {
    type: centerTypes.EDIT_WEBSITE_CENTER_DETAILS,
    id,
    payload,
    cb,
    cbError
  };
};
