import React from 'react';
import styles from './radio.module.scss';
import PropTypes from 'prop-types';

const RadioButton = ({ text, value, name, onChange, onClick, checked }) => {
  return (
    <div className={styles.radioButton}>
      <input
        type="radio"
        id="radio1"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      <p>{text}</p>
    </div>
  );
};

RadioButton.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.any,
  onClick: PropTypes.any,
  checked: PropTypes.bool
};
export default RadioButton;
