const procedureTypes = {
  GET_ALL_PROCEDURE: 'GET_ALL_PROCEDURE',
  SET_ALL_PROCEDURE: 'SET_ALL_PROCEDURE',
  CREATE_PROCEDURE_CATEGORIES: 'CREATE_PROCEDURE_CATEGORIES',
  SAVE_PROCEDURE_CATEGORIES: 'SAVE_PROCEDURE_CATEGORIES',
  CREATE_PROCEDURE_TYPE: 'CREATE_PROCEDURE_TYPE',
  SAVE_PROCEDURE_TYPE: 'SAVE_PROCEDURE_TYPE',
  GET_PROCEDURE_CATEGORIES: 'GET_PROCEDURE_CATEGORIES',
  SET_PROCEDURE_CATEGORIES: 'SET_PROCEDURE_CATEGORIES',
  GET_PROCEDURETYPE_CATEGORIES: 'GET_PROCEDURETYPE_CATEGORIES',
  SET_PROCEDURETYPE_CATEGORIES: 'SET_PROCEDURETYPE_CATEGORIES',
  GET_CATEGORY_TEST: 'GET_CATEGORY_TEST',
  SET_CATEGORY_TEST: 'SET_CATEGORY_TEST',
  CREATE_PROCEDURE_TEST: 'CREATE_PROCEDURE_TEST',
  SAVE_PROCEDURE_TEST: 'SAVE_PROCEDURE_TEST',
  CREATE_PRICE_TYPE: 'CREATE_PRICE_TYPE',
  SAVE_PRICE_TYPE: 'SAVE_PRICE_TYPE',
  GET_PRICE_TYPES: 'GET_PRICE_TYPES',
  SET_PRICE_TYPES: 'SET_PRICE_TYPES',
  EDIT_TEST_TYPES: 'EDIT_TEST_TYPES',
  GET_UNGROUPED_TEST: 'GET_UNGROUPED_TEST',
  SET_UNGROUPED_TEST: 'SET_UNGROUPED_TEST',
  DELETE_TEST: 'DELETE_TEST',
  DO_UPDATE_TESTS: 'DO_UPDATE_TESTS',
  DO_CHECKER_PROCEDURE: 'DO_CHECKER_PROCEDURE'
};

export default procedureTypes;
