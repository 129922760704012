import React from 'react';
import Text from 'components/Text/Text';
import classnames from 'classnames';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

const CardList = ({ title, subtitle, children, packages, isActive }) => {
  const activeClass = () => {
    if (isActive) {
      return styles[`indicator__${isActive}`];
    }
  };

  return (
    <div
      className={classnames(styles.card, styles.indicator, activeClass(), `${styles[isActive]}`)}
    >
      <div className={packages && styles.card_flex}>
        <Text className={styles.card_flex_text} as="h1">
          {title}
        </Text>
        <Text className={styles.card_flex_text} as="p">
          {subtitle}
        </Text>
      </div>
      <div className={styles.card__child}>{children}</div>
    </div>
  );
};

CardList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  packages: PropTypes.bool,
  isActive: PropTypes.string
};

export default CardList;
