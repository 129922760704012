import actionTypes from './auth.types';

export const initialState = {
  signIn: {}
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SIGNIN_DATA:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          ...payload
        }
      };
    default:
      return state;
  }
};

export default authReducer;
