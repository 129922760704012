import dashboardTypes from './dashboard.types';

export const getDashboardCardAction = (payload, cb, cbError) => ({
  type: dashboardTypes.GET_DASHBOARDCARD_ANALYTICS,
  payload,
  cb,
  cbError
});

export const getPatientVisitAction = (payload, cb, cbError) => ({
  type: dashboardTypes.GET_PATIENT_VISIT,
  payload,
  cb,
  cbError
});
