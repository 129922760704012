import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Dropdown/dropdown.module.scss';

export const Option = ({ children, image, onClick, dropStyles, large, dataTestId }) => {
  Option.propTypes = {
    children: PropTypes.ReactNode,
    image: PropTypes.string,
    onClick: PropTypes.func,
    dropStyles: PropTypes.object,
    large: PropTypes.bool,
    dataTestId: PropTypes.string
  };
  return (
    <div
      className={large ? styles.drop__large : styles.drop__optionContainer}
      style={dropStyles}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <span className={styles.drop__imageLeft}>
        {image && <img src={image} alt="drop-images" />}
      </span>
      <div className={styles.drop__option} aria-hidden>
        {' '}
        {children}
      </div>{' '}
      <span className={styles.drop__imageRight}></span>
    </div>
  );
};
