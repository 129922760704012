import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import procedureTypes from './procedure.types';

export function* getProcedures(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_PROCEDURE}/type?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: procedureTypes.SET_ALL_PROCEDURE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCreateProcedureCategories(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_PROCEDURE}`, data.params));
    if (res.status === 201) {
      yield put({ type: procedureTypes.SAVE_PROCEDURE_CATEGORIES, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCreateProcedureType(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_PROCEDURE}/type`, data.params));
    if (res.status === 201) {
      yield put({ type: procedureTypes.SAVE_PROCEDURE_TYPE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getProcedureCategories(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_PROCEDURE}/${queryFormatter(data.id)}/categories`)
    );
    if (res.status === 200) {
      yield put({ type: procedureTypes.SET_PROCEDURE_CATEGORIES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getProcedureTypeCategories(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.FETCH_PROCEDURE}/${data.id}/categories`));
    if (res.status === 200) {
      yield put({ type: procedureTypes.SET_PROCEDURETYPE_CATEGORIES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getCategoryTest(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_PROCEDURE}/${data.id}/tests?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: procedureTypes.SET_CATEGORY_TEST, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCreateProcedureTest(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_PROCEDURE}/tests`, data.params));
    if (res.status === 201) {
      yield put({ type: procedureTypes.SAVE_PROCEDURE_TEST, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response.data || '';
    cbError(alert);
  }
}

export function* doCreatePriceTypes(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_PROCEDURE}/pricing-type`, data.params));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response.data.message || '';
    cbError(alert);
  }
}

export function* getPriceTypes(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(`${API.FETCH_PROCEDURE}/pricing-types`));
    if (res.status === 200) {
      yield put({ type: procedureTypes.SET_PRICE_TYPES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editSingleTest(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.FETCH_PROCEDURE}/edit/${data.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getUngroupedTests(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_PROCEDURE}/tests/all?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: procedureTypes.SET_UNGROUPED_TEST, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deleteSingleTest(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.delete(`${API.FETCH_PROCEDURE}/delete/${data.id}`));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doUpdateTests(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.FETCH_PROCEDURE}/edit/${data.id}`, data.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response.data || '';
    cbError(alert);
  }
}

export function* doUploadCheckerProcedure(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_PROCEDURE}/procedure-list`, data.data));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response.data || '';
    cbError(alert);
  }
}
function* watchProcedureSaga() {
  yield takeEvery(procedureTypes.GET_ALL_PROCEDURE, getProcedures);
  yield takeEvery(procedureTypes.CREATE_PROCEDURE_TYPE, doCreateProcedureType);
  yield takeEvery(procedureTypes.GET_PROCEDURE_CATEGORIES, getProcedureCategories);
  yield takeEvery(procedureTypes.CREATE_PROCEDURE_CATEGORIES, doCreateProcedureCategories);
  yield takeEvery(procedureTypes.GET_PROCEDURETYPE_CATEGORIES, getProcedureTypeCategories);
  yield takeEvery(procedureTypes.GET_CATEGORY_TEST, getCategoryTest);
  yield takeEvery(procedureTypes.CREATE_PROCEDURE_TEST, doCreateProcedureTest);
  yield takeEvery(procedureTypes.CREATE_PRICE_TYPE, doCreatePriceTypes);
  yield takeEvery(procedureTypes.GET_PRICE_TYPES, getPriceTypes);
  yield takeEvery(procedureTypes.EDIT_TEST_TYPES, editSingleTest);
  yield takeEvery(procedureTypes.GET_UNGROUPED_TEST, getUngroupedTests);
  yield takeEvery(procedureTypes.DELETE_TEST, deleteSingleTest);
  yield takeEvery(procedureTypes.DO_UPDATE_TESTS, doUpdateTests);
  yield takeEvery(procedureTypes.DO_CHECKER_PROCEDURE, doUploadCheckerProcedure);
}

export default watchProcedureSaga;
