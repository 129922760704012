import { Routes, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import PrivateRoute from '../../PrivateRoute';

export const ProceduresPage = lazy(() => import('pages/Procedures/Procedures'));
export const CreateProceduresPage = lazy(() =>
  import('container/procedures/createProcedure/index')
);
export const ProcedureDetailsPage = lazy(() => import('container/procedures/procedureDetails'));
export const CreateProcedureTestPage = lazy(() =>
  import('container/procedures/createProcedureTest')
);
export const SelectProcedurePage = lazy(() => import('container/clinicalReport/selectProcedure'));
export const AssignProcedurePage = lazy(() =>
  import('container/Centers/CreateCenters/AssignProcedure')
);
export const SingleCentreProcedurePage = lazy(() =>
  import('container/Centers/CreateCenters/CentreProcedure')
);
export const CreateCategoryPage = lazy(() => import('container/procedures/createCategory/index'));
export const AllowableProcedurePage = lazy(() => import('container/procedures/allowableProcedure'));

export default function ProcedureRoutes() {
  return (
    <Routes>
      <Route
        path="/procedure"
        element={
          <PrivateRoute>
            <ProceduresPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/create/procedure"
        element={
          <PrivateRoute>
            <CreateProceduresPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit/procedure"
        element={
          <PrivateRoute>
            <CreateProceduresPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/procedure/:id/:title/:code/:page"
        element={
          <PrivateRoute>
            <ProcedureDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/create/tests/:title/:code"
        element={
          <PrivateRoute>
            <CreateProcedureTestPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/procedure/create"
        element={
          <PrivateRoute>
            <SelectProcedurePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/procedure/edit/:id"
        element={
          <PrivateRoute>
            <SelectProcedurePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/assign-procedure/:id"
        element={
          <PrivateRoute>
            <AssignProcedurePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/create-procedure/:id"
        element={
          <PrivateRoute>
            <SingleCentreProcedurePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/edit-procedure/:id"
        element={
          <PrivateRoute>
            <SingleCentreProcedurePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/create/category/:id/:title/:code"
        element={
          <PrivateRoute>
            <CreateCategoryPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/allowable/procedure"
        element={
          <PrivateRoute>
            <AllowableProcedurePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
