import CenterFormLayout from 'components/Layout/centerFormLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CreateCategoryForm from './createCategoryForm';

const CreateCategory = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    return navigate(-1);
  };
  return (
    <div>
      <CenterFormLayout
        backTheme="tertiary"
        backSize="sm"
        closeTheme="tertiary"
        closeSize="sm"
        title="Create Category"
        onClose={handleClose}
      >
        <CreateCategoryForm />
      </CenterFormLayout>
    </div>
  );
};

export default CreateCategory;
