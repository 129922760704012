import Button from 'components/Button/Button';
import CenterFormLayout from 'components/Layout/centerFormLayout';
import ReactSelect from 'components/Select/ReactSelect';
import React, { useEffect, useState } from 'react';
import styles from '../packages.module.scss';
import PackageSummary from '../allPackages/packageSummary';
import PropTypes from 'prop-types';
import useLoaderState from 'hooks/useLoading';
import { useDispatch, useSelector } from 'react-redux';
import {
  editSuperPackageAction,
  getPartnerPackagesAction,
  getSuperPackageDetailsAction
} from 're-ducks/packages/packages.action';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllPartnersAction } from 're-ducks/partner';

const EditPackagesToSuperPackageModal = () => {
  const { loading, setLoading } = useLoaderState();
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState({});
  const { super_package_data } = useSelector((state) => state.packages);
  const [packagesToBeAdded, setPackagesToBeAdded] = useState([]);
  const [payingPartner, setPayingPartner] = useState([]);
  const packagesToBeAddedId = packagesToBeAdded?.map((item) => item?.value);
  const params = useParams();
  const navigate = useNavigate();

  const packagesOption = packages
    ?.map((item) => ({
      label: item?.name,
      value: item?.id
    }))
    ?.filter((i) => !packagesToBeAddedId?.includes(i.value));

  const handlePackagesChange = (e) => {
    setSelectedPackages(e);
    setPackagesToBeAdded([...packagesToBeAdded, { label: e?.label, value: e?.value }]);
  };

  useEffect(() => {
    setPackagesToBeAdded(
      super_package_data?.packageDetails?.map((el) => ({
        label: el?.name,
        value: el?.id
      }))
    );
  }, []);

  useEffect(() => {
    dispatch(
      getAllPartnersAction(
        {
          params: {
            type: super_package_data?.partnership_type?.toLowerCase()
          }
        },
        (res) => {
          setPayingPartner(
            res?.partners?.filter((i) => i.name === super_package_data?.paying_partner?.value)[0]
          );
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getPartnerPackagesAction(
        {
          id: payingPartner?.id
        },
        (res) => {
          setPackages(res?.map((i) => ({ name: i?.name, id: i?.id })));
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, [payingPartner?.id]);

  const handleEditSuperPackageSubmission = () => {
    setLoading(true);
    dispatch(
      editSuperPackageAction(
        {
          id: params?.id,
          params: {
            name: super_package_data?.name,
            package_ids: packagesToBeAddedId
          }
        },
        (res) => {
          setLoading(false);
          dispatch({
            type: 'SAVE_SUPER_PACKAGE_DATA',
            payload: {}
          });
          toast.success(res?.message);
          navigate('/packages?type=superpackages');
          dispatch(
            getSuperPackageDetailsAction(
              {
                id: params?.id
              },
              () => {},
              () => {}
            )
          );
        },
        (error) => {
          setLoading(false);
          toast.error(error);
        }
      )
    );
  };

  return (
    <div>
      <CenterFormLayout
        backSize="sm"
        onBackClick={() => navigate('/packages?type=superpackages')}
        onClose={() => navigate('/packages?type=superpackages')}
        closeTheme="tertiary"
        closeSize="sm"
        title="Edit Super Package"
        caption="Selected Packages are tied to this Super Package."
      >
        <div className={styles.packages_create__packageProcedures}>
          <div></div>
          <div className={styles.packages_create__details_form}>
            <div>
              <ReactSelect
                label="Package Name"
                loading={loading}
                options={packagesOption}
                onChange={(e) => handlePackagesChange(e)}
                disabled={''}
                closeMenuOnSelect={false}
                value={selectedPackages}
              />
            </div>
            <div>
              <Button
                theme="primary"
                size="md"
                disabled={packagesToBeAdded?.length === 0}
                onClick={() => handleEditSuperPackageSubmission()}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </div>
          <PackageSummary
            allProcedureTests={packagesToBeAdded}
            setAllProcedureTests={setPackagesToBeAdded}
            packagename
          />
        </div>
      </CenterFormLayout>
    </div>
  );
};
EditPackagesToSuperPackageModal.propTypes = {
  isOpen: PropTypes.any,
  isClose: PropTypes.any,
  closeEditSuperPackageModal: PropTypes.any,
  rowId: PropTypes.string
};
export default EditPackagesToSuperPackageModal;
