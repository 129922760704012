import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './avatar.module.scss';

const Avatar = ({ name, className, profile, url, width, height, font }) => {
  return (
    <span className={classNames(className, 'flex')}>
      <div>
        {url ? (
          <img src={url} className={profile ? styles.profile__img : styles.header__img} />
        ) : (
          <span
            className={classNames(profile ? styles.avatar__profile : styles.avatar__placeholder)}
            style={{ width: width, height: height, fontSize: font }}
          >
            {name?.split(' ')[0]?.charAt(0)?.toUpperCase()}
            {name?.split(' ')[1]?.charAt(0)?.toUpperCase()}
          </span>
        )}
      </div>
    </span>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  profile: PropTypes.bool,
  url: PropTypes.string,
  pic: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  font: PropTypes.string
};

Avatar.defaultProps = {
  className: ''
};

export default Avatar;
