import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { formSchema, removeSpaceRegExp } from 'utils/validation';
import { handleNameChange } from 'helpers/nameChange';

import Input from 'components/FormInput/FormInput';
import Button from 'components/Button/Button';
import { states } from 'const/states';
import Select from 'components/Select';
import styles from './centerForm.module.scss';

import { createBranchAction } from 're-ducks/centers';
import { toast } from 'react-toastify';
import { removeEmpty } from 'utils/removeEmptyValues';
import ReactPhoneInput from 'components/FormInput/PhoneInput';

const CenterDetailForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [Userstate, setUserState] = useState('');
  const [selectState, setSelectState] = useState({
    name: Userstate?.name
  });

  const [Userbranch, setBranch] = useState('');
  const [selectBranch, setSelectBranch] = useState();

  const branchType = [
    { name: 'Embedded', id: 1 },
    { name: 'Spike', id: 2 },
    { name: 'Full Service', id: 3 }
  ];

  useEffect(() => {
    const { id } = { ...Userstate };
    setSelectState({ name: id ?? '' });
  }, [Userstate]);

  useEffect(() => {
    const { id } = { ...Userbranch };
    setSelectBranch({ name: id ?? '' });
  }, [Userbranch]);

  const handleCreateCenter = (values) => {
    const payload = {
      name: values.name,
      email: values?.email.replace(removeSpaceRegExp, '').trim(),
      phone_number: values.phone_number,
      branch_type: handleNameChange(selectBranch?.name),
      address: values.address,
      state: selectState?.name,
      city: values.city,
      referral_percentage: values.referral_percentage
    };

    setLoading(true);
    dispatch(
      createBranchAction(
        removeEmpty(payload),
        (res) => {
          toast.success('Centre Created');
          navigate(`/${res.id}/assign-admin`);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error(err);
        }
      )
    );
  };

  return (
    <div className={styles.mainform}>
      <Formik
        onSubmit={handleCreateCenter}
        validationSchema={formSchema}
        initialValues={{
          name: '',
          email: '',
          address: '',
          phone_number: '',
          longitude: '',
          latitude: '',
          branch_type: '',
          city: '',
          referral_percentage: ''
        }}
      >
        {(formik) => (
          <Form>
            <div className={styles.mainform__input}>
              <Input
                label="Center Name"
                type="text"
                placeholder="Center Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
                id="name"
              />
              {formik.errors.name ? <span className="error">{formik.errors.name}</span> : null}
            </div>
            <div className={styles.mainform__input}>
              <Select
                label="Centre Type"
                type="text"
                placeholder="Select"
                options={branchType.map((item) => ({
                  name: item.name,
                  id: item.id
                }))}
                selected={selectBranch}
                handleChange={setSelectBranch}
              />
            </div>
            <div className={styles.mainform__input}>
              <Input
                label="Address"
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="address"
              />
            </div>

            <div className={styles.mainform__input}>
              <Input
                label="Referral’s Percentage(%)"
                placeholder="20%"
                value={formik.values.referral_percentage}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="referral_percentage"
              />
              {formik.errors.referral_percentage ? (
                <span className="error">{formik.errors.referral_percentage}</span>
              ) : null}
            </div>

            <div className={styles.mainform__input}>
              <Input
                label="Email Address"
                type="email"
                placeholder="User@email.com"
                onChange={formik.handleChange}
                name="email"
              />
              {formik.errors.email ? <span className="error">{formik.errors.email}</span> : null}
            </div>
            <div style={{ paddingBottom: '5%' }}>
              <ReactPhoneInput
                placeholder="+234 819 899 3843"
                value={formik?.values?.phone_number}
                onChange={(value) => formik?.setFieldValue('phone_number', value)}
                country={'ng'}
              />
              {formik.errors.phone_number ? (
                <span className="error">{formik.errors.phone_number}</span>
              ) : null}{' '}
            </div>

            <div className={styles.mainform__double}>
              <div className={styles.mainform__select}>
                <Select
                  label="State"
                  placeholder="Select"
                  options={states.map((item) => ({
                    name: item.name,
                    id: item.id
                  }))}
                  selected={selectState}
                  handleChange={setSelectState}
                />
              </div>
              <div>
                <Input
                  label="City"
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formik.values.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.city ? <span className="error">{formik.errors.city}</span> : null}
              </div>
            </div>
            <div className={styles.mainform}>
              <Button
                theme="primary"
                size="md"
                type="submit"
                disabled={!formik.isValid || !formik.dirty || loading}
                loading={loading}
              >
                Create centre
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CenterDetailForm;
