import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import reportTypes from './report.types';

export function* doCreateReport(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.SETTING}/component`, data));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getReports(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.SETTING}/components?${queryFormatter(data)}`));
    if (res.status === 200) {
      yield put({ type: reportTypes.SET_REPORTS, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSingleReport(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.SETTING}/component/${data.id}`));
    if (res.status === 200) {
      yield put({ type: reportTypes.SET_SINGLE_REPORT, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchAllTests(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(`${API.FETCH_PROCEDURE}/tests/all`));
    if (res.status === 200) {
      yield put({ type: reportTypes.SET_TESTS, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doEditReport(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.SETTING}/component`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doDeleteReport(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.delete(`${API.SETTING}/component/${data.id}`));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchReportSaga() {
  yield takeEvery(reportTypes.CREATE_REPORT, doCreateReport);
  yield takeEvery(reportTypes.FETCH_REPORTS, getReports);
  yield takeEvery(reportTypes.FETCH_SINGLE_REPORT, getSingleReport);
  yield takeEvery(reportTypes.FETCH_TESTS, fetchAllTests);
  yield takeEvery(reportTypes.EDIT_TESTS, doEditReport);
  yield takeEvery(reportTypes.DELETE_SINGLE_REPORT, doDeleteReport);
}

export default watchReportSaga;
