import React from 'react';
import placeholder from 'assets/icons/table-placeholder.svg';
import styles from 'components/Table/table.module.scss';
import PropTypes from 'prop-types';

const EmptyState = () => {
  return (
    <div className={styles.table__empty}>
      <div>
        <img src={placeholder} alt="" /> <p>No data yet</p>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  onClick: PropTypes.func
};

export default EmptyState;
