import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import { CreateCenter } from 'container/Centers/CreateCenters/CenterCenter';
import CreateWebsiteCenter from 'container/websiteManagement/WebsiteCenters/CreateWebsiteCenter';

export const CentersPage = lazy(() => import('pages/Centers/Center'));
export const CenterDetailsPage = lazy(() =>
  import('container/Centers/CenterDetails/CenterDetails')
);
export const ViewUserPage = lazy(() => import('container/Centers/CenterDetails/ViewUserDetails'));
export const EditCenterDetailsPage = lazy(() =>
  import('container/Centers/CenterDetails/EditCenterDetails')
);
export const EditUserRolePage = lazy(() => import('container/Centers/CenterDetails/EditUserRole'));
export const CenterProcedurePage = lazy(() =>
  import('container/Centers/CreateCenters/CenterProcedure')
);
export const EditWebsiteCenterPage = lazy(() =>
  import('container/websiteManagement/WebsiteCenters/EditWebsiteCenter')
);
export const WebsiteCenterDetailsPage = lazy(() =>
  import('container/websiteManagement/WebsiteCenters/WebsiteCenterDetails')
);

export default function CenterRoutes() {
  return (
    <Routes>
      <Route
        path="/centers"
        element={
          <PrivateRoute>
            <CentersPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-center"
        element={
          <PrivateRoute>
            <CreateCenter />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-website-center"
        element={
          <PrivateRoute>
            <CreateWebsiteCenter />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-website-center/:id"
        element={
          <PrivateRoute>
            <EditWebsiteCenterPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/website-management/website-centers/details/:id"
        element={
          <PrivateRoute>
            <WebsiteCenterDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/centers/details/:id/:page"
        element={
          <PrivateRoute>
            <CenterDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/centers/:branch_id/:id"
        element={
          <PrivateRoute>
            <ViewUserPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-center/:id/:page"
        element={
          <PrivateRoute>
            <EditCenterDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/centers/:branch_id/:id/edit-role"
        element={
          <PrivateRoute>
            <EditUserRolePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/centers/:branch_id/:id/edit-role/:room"
        element={
          <PrivateRoute>
            <EditUserRolePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/centers/:id/:code/:name/:category"
        element={
          <PrivateRoute>
            <CenterProcedurePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
