import packageTypes from './packages.types';

export const getAllPackagesAction = (payload, cb, cbError) => ({
  type: packageTypes.GET_ALL_PACKAGES,
  payload,
  cb,
  cbError
});

export const doCreatePackageAction = (payload, cb, cbError) => ({
  type: packageTypes.CREATE_SINGLE_PACKAGE,
  payload,
  cb,
  cbError
});

export const getPackageDetailsAction = (payload, cb, cbError) => ({
  type: packageTypes.GET_PACKAGE_DETAILS,
  payload,
  cb,
  cbError
});

export const getPackageProceduresAction = (payload, cb, cbError) => ({
  type: packageTypes.GET_PACKAGE_TESTS,
  payload,
  cb,
  cbError
});

export const editPackageAction = (payload, cb, cbError) => ({
  type: packageTypes.EDIT_PACKAGE,
  payload,
  cb,
  cbError
});

export const editPackageStatusAction = (payload, cb, cbError) => ({
  type: packageTypes.PACKAGE_STATUS,
  payload,
  cb,
  cbError
});

export const createSuperPackageAction = (payload, cb, cbError) => ({
  type: packageTypes.CREATE_SUPER_PACKAGE,
  payload,
  cb,
  cbError
});

export const getAllSuperPackagesAction = (payload, cb, cbError) => ({
  type: packageTypes.GET_ALL_SUPERPACKAGES,
  payload,
  cb,
  cbError
});

export const editSuperPackageAction = (payload, cb, cbError) => ({
  type: packageTypes.EDIT_SUPER_PACKAGE,
  payload,
  cb,
  cbError
});

export const editSuperPackageStatusAction = (payload, cb, cbError) => ({
  type: packageTypes.EDIT_SUPERPACKAGE_STATUS,
  payload,
  cb,
  cbError
});

export const getSuperPackageDetailsAction = (payload, cb, cbError) => ({
  type: packageTypes.GET_SUPERPACKAGES_DETAILS,
  payload,
  cb,
  cbError
});

export const removePackageFromSuperPackageAction = (payload, cb, cbError) => ({
  type: packageTypes.REMOVE_PACKAGE_FROM_SUPERPACKAGE,
  payload,
  cb,
  cbError
});

export const getPartnerPackagesAction = (payload, cb, cbError) => ({
  type: packageTypes.GET_PARTNER_PACKAGES,
  payload,
  cb,
  cbError
});
