import partnerTypes from './partners.types';

export const createPartnerAction = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_PARTNER,
  payload,
  cb,
  cbError
});

export const getPartnerTypeAction = (cb, cbError) => ({
  type: partnerTypes.GET_PARTNERSHIP_TYPE,
  cb,
  cbError
});

export const createPartnersAction = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_PARTNERS,
  payload,
  cb,
  cbError
});

export const getAllPartnersAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_ALL_PARTNERS,
  payload,
  cb,
  cbError
});

export const getPartnerDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_PARTNER_DETAILS,
  payload,
  cb,
  cbError
});

export const getPartnerTransactionDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_PARTNER_TRANSACTION_DETAILS,
  payload,
  cb,
  cbError
});

export const editPartnerDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.EDIT_PARTNER_DETAILS,
  payload,
  cb,
  cbError
});

export const deletePartnerAction = (payload, cb, cbError) => ({
  type: partnerTypes.DELETE_PARTNER,
  payload,
  cb,
  cbError
});

export const updatePartnerStatusAction = (payload, cb, cbError) => ({
  type: partnerTypes.UPDATE_PARTNER_STATUS,
  payload,
  cb,
  cbError
});

export const getPartnerOutstandingTransactionDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_PARTNER_OUTSTANDING_TRANSACTION_DETAILS,
  payload,
  cb,
  cbError
});

export const createPartnerPaymentAction = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_PARTNERS_PAYMENT,
  payload,
  cb,
  cbError
});

export const getPartnerPatientDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_PARTNER_PATIENT_DETAILS,
  payload,
  cb,
  cbError
});

export const createInvoiceAction = (payload, cb, cbError) => ({
  type: partnerTypes.CREATE_INVOICE,
  payload,
  cb,
  cbError
});

export const getInvoiceDetailsAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_INVOICE_DETAILS,
  payload,
  cb,
  cbError
});

export const getPartnerInvoicesAction = (payload, cb, cbError) => ({
  type: partnerTypes.GET_PARTNER_INVOICES,
  payload,
  cb,
  cbError
});
