import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import packageTypes from './packages.types';

export function* getPackages(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.FETCH_PACKAGE}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: packageTypes.SET_ALL_PACKAGES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCreatePackage(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.FETCH_PACKAGE}`, data.params));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response.data?.message || '';
    cbError(alert);
  }
}

export function* getPackagesDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.FETCH_PACKAGE}/${data.id}`));
    if (res.status === 200) {
      yield put({ type: packageTypes.SET_PACKAGE_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getPackagesProcedures(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_PROCEDURE}/package-tests?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: packageTypes.SET_PACKAGE_TESTS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editPackage(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.FETCH_PACKAGE}/${data.id}`, data.params));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editPackageStatus(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.FETCH_PACKAGE}/${data.id}/status`, data.params));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doCreateSuperPackage(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.SUPERPACKAGES}`, data.params));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response.data?.message || '';
    cbError(alert);
  }
}

export function* getAllSuperPackages(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.SUPERPACKAGES}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: packageTypes.SET_ALL_SUPERPACKAGES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* getSuperPackagesDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.SUPERPACKAGES}/${data.id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      yield put({ type: packageTypes.SET_SUPERPACKAGES_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editSuperPackageStatus(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.SUPERPACKAGES}/${data.id}/status`, data.params));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* removePackageFromSuperPackage(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.SUPERPACKAGES}/${data.id}/package`, data.params));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editSuperPackage(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.SUPERPACKAGES}/${data.id}`, data.params));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* getPartnersPackage(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.FETCH_PACKAGE}/${data.id}/partner-packages`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchPackagesSaga() {
  yield takeEvery(packageTypes.GET_ALL_PACKAGES, getPackages);
  yield takeEvery(packageTypes.CREATE_SINGLE_PACKAGE, doCreatePackage);
  yield takeEvery(packageTypes.GET_PACKAGE_DETAILS, getPackagesDetails);
  yield takeEvery(packageTypes.GET_PACKAGE_TESTS, getPackagesProcedures);
  yield takeEvery(packageTypes.EDIT_PACKAGE, editPackage);
  yield takeEvery(packageTypes.PACKAGE_STATUS, editPackageStatus);
  yield takeEvery(packageTypes.CREATE_SUPER_PACKAGE, doCreateSuperPackage);
  yield takeEvery(packageTypes.GET_ALL_SUPERPACKAGES, getAllSuperPackages);
  yield takeEvery(packageTypes.EDIT_SUPER_PACKAGE, editSuperPackage);
  yield takeEvery(packageTypes.EDIT_SUPERPACKAGE_STATUS, editSuperPackageStatus);
  yield takeEvery(packageTypes.GET_SUPERPACKAGES_DETAILS, getSuperPackagesDetails);
  yield takeEvery(packageTypes.REMOVE_PACKAGE_FROM_SUPERPACKAGE, removePackageFromSuperPackage);
  yield takeEvery(packageTypes.GET_PARTNER_PACKAGES, getPartnersPackage);
}

export default watchPackagesSaga;
