import React, { useEffect, useState } from 'react';
import HomePagePackages from './homePagePackages';
import Tabs from 'components/Tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import WebsiteCenter from 'container/websiteManagement/WebsiteCenters/WebsiteCenter';

const WebsiteManagement = () => {
  const tabItems = [
    {
      id: 1,
      label: 'Package management',
      type: 'packagesmanagement'
    },
    {
      id: 3,
      label: 'Website Centers',
      type: 'websitecenters'
    }
  ];
  const { search } = useLocation();
  const getType = new URLSearchParams(search).get('type');
  const [activeTabContent, setActiveTabContent] = useState(tabItems[0]);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const selectedTab = tabItems.find((item) => item.type === getType) || tabItems[0];
    setActiveTabContent(selectedTab);
  }, [getType]);

  return (
    <div>
      <div className="actionBox">
        <div className="actionBox__create">
          <p style={{ marginTop: '15px' }}>{count} counts</p>
          <br />
        </div>
      </div>
      <Tabs
        tabs={tabItems}
        active={activeTabContent}
        onClick={() => {}}
        setActive={(tab) => {
          setActiveTabContent(tab);
          navigate(`/website-management?type=${tab.type}`);
          setPage(1);
        }}
      />
      {activeTabContent?.label === 'Package management' && (
        <HomePagePackages setCount={setCount} setPage={setPage} page={page} />
      )}
      {activeTabContent?.label === 'Website Centers' && (
        <WebsiteCenter setGetBranchCount={setCount} getType={getType} />
      )}
    </div>
  );
};

export default WebsiteManagement;
