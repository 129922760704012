import React from 'react';
import { useState } from 'react';
import { LuClock3 } from 'react-icons/lu';
import PropTypes from 'prop-types';
import styles from './timePicker.module.scss';

const TimePicker = ({ onChange, value, label, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(value);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    onChange(time);
    setIsOpen(false);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let i = 7; i < 19; i++) {
      const hour = i % 12 || 12;
      const ampm = i < 12 ? 'AM' : 'PM';
      const formattedTime = `${hour}:00 ${ampm}`;
      options.push(formattedTime);
    }
    return options;
  };
  return (
    <div className={styles.container}>
      {label && <p className={styles.label}>{label}</p>}
      <div className={styles.dropdown} onClick={toggleDropdown}>
        <div className={styles.box}>
          <div className={styles.box__input}>{selectedTime}</div>
          <LuClock3 style={{ width: '16px', height: '16px' }} onClick={toggleDropdown} />
        </div>
        {isOpen && (
          <ul className={styles.ul}>
            {generateTimeOptions().map((time) => (
              <li
                key={time}
                onClick={() => handleTimeSelect(time)}
                data-testid="handleTimeSelection"
                className={`${selectedTime === time ? styles.selected : styles.time}`}
              >
                {time}
              </li>
            ))}
          </ul>
        )}
      </div>
      <p style={{ fontSize: '14px', color: 'red' }}>{error}</p>
    </div>
  );
};
TimePicker.propTypes = {
  time: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string
};
export default TimePicker;
