import financialTypes from './financial.types';

export const initialState = {
  cardAnalytics: {},
  centersCollectedRevenues: [],
  centersBookedRevenues: [],
  channelCollectedRevenues: [],
  channelBookedRevenues: [],
  graphTrend: [],
  topLineCollectedRevenue: {},
  topLineBookedRevenue: {},
  channelCollectedDetails: [],
  channelBookedDetails: []
};

export const financialReportReducers = (state = initialState, action) => {
  switch (action.type) {
    case financialTypes.SET_CARD_ANALYTICS:
      return {
        ...state,
        cardAnalytics: action.payload
      };
    case financialTypes.SET_CENTERS_COLLECTED_REVENUES:
      return {
        ...state,
        centersCollectedRevenues: action.payload
      };
    case financialTypes.SET_CENTERS_BOOKED_REVENUES:
      return {
        ...state,
        centersBookedRevenues: action.payload
      };
    case financialTypes.SET_CHANNELS_COLLECTED_REVENUES:
      return {
        ...state,
        channelCollectedRevenues: action.payload
      };
    case financialTypes.SET_CHANNELS_BOOKED_REVENUES:
      return {
        ...state,
        channelBookedRevenues: action.payload
      };
    case financialTypes.SET_GRAPH_TREND:
      return {
        ...state,
        graphTrend: action.payload
      };
    case financialTypes.SET_COLLECTED_REVENUE_TOPLINE:
      return {
        ...state,
        topLineCollectedRevenue: action.payload
      };
    case financialTypes.SET_BOOKED_REVENUE_TOPLINE:
      return {
        ...state,
        topLineBookedRevenue: action.payload
      };
    case financialTypes.SET_CHANNEL_COLLECTED_REVENUES_DETAIL:
      return {
        ...state,
        channelCollectedDetails: action.payload
      };
    case financialTypes.SET_CHANNEL_BOOKED_REVENUES_DETAIL:
      return {
        ...state,
        channelBookedDetails: action.payload
      };
    default:
      return state;
  }
};

export default financialReportReducers;
