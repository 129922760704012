import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const ReferralsPage = lazy(() => import('pages/Referrals/Referrals'));
export const ReferralDetailsPage = lazy(() => import('container/referral/referralDetails'));
export const ReferralPaymentPage = lazy(() => import('container/referral/referralPayment'));
export const ReferralDuePaymentPage = lazy(() => import('container/referral/referralDuePayment'));

export default function ReferralRoutes() {
  return (
    <Routes>
      <Route
        path="/referrals"
        element={
          <PrivateRoute>
            <ReferralsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/referrals/:id/:page"
        element={
          <PrivateRoute>
            <ReferralDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/referrals/payment/:id"
        element={
          <PrivateRoute>
            <ReferralPaymentPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/referrals/duePayment/:referralID/:paymentID"
        element={
          <PrivateRoute>
            <ReferralDuePaymentPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
