import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import SuperPackageDetails from 'container/packages/superpackages/superPackageDetails/superPackageDetails';
import EditSuperPackageModal from 'container/packages/superpackages/editSuperPackageModal';
import EditPackagesToSuperPackageModal from 'container/packages/superpackages/editPackagesToSuperPackageModal';
export const PackagesPage = lazy(() => import('pages/Packages/Packages'));
export const CreatePackagePage = lazy(() => import('container/packages/allPackages/createPackage'));
export const EditPackagePage = lazy(() => import('container/packages/allPackages//editPackage'));
export const PackageDetailsPage = lazy(() =>
  import('container/packages/allPackages//PackageDetails')
);
export const SummaryPage = lazy(() => import('container/packages/allPackages//Summary'));
export const PackageProceduresPage = lazy(() =>
  import('container/packages/allPackages/packageProcedures')
);
export const CreateSuperPackage = lazy(() =>
  import('container/packages/superpackages/createSuperPackage')
);
export const AddPackagesToSuperPackage = lazy(() =>
  import('container/packages/superpackages/addPackagesToSuperPackage')
);

export default function PackageRoutes() {
  return (
    <Routes>
      <Route
        path="/packages"
        element={
          <PrivateRoute>
            <PackagesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/packages/create"
        element={
          <PrivateRoute>
            <CreatePackagePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/packages/edit/:id/:page"
        element={
          <PrivateRoute>
            <EditPackagePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/packages/details/:id/:page"
        element={
          <PrivateRoute>
            <PackageDetailsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/packages/summary"
        element={
          <PrivateRoute>
            <SummaryPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/packages/summary/:id"
        element={
          <PrivateRoute>
            <SummaryPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/packages/procedures"
        element={
          <PrivateRoute>
            <PackageProceduresPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/packages/procedures/edit/:id/:page"
        element={
          <PrivateRoute>
            <PackageProceduresPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/superpackage/create"
        element={
          <PrivateRoute>
            <CreateSuperPackage />
          </PrivateRoute>
        }
      />

      <Route
        path="/superpackage/addpackage"
        element={
          <PrivateRoute>
            <AddPackagesToSuperPackage />
          </PrivateRoute>
        }
      />

      <Route
        path="/superpackage/procedures"
        element={
          <PrivateRoute>
            <AddPackagesToSuperPackage />
          </PrivateRoute>
        }
      />
      <Route
        path="/packages/superpackage-details/:id"
        element={
          <PrivateRoute>
            <SuperPackageDetails />
          </PrivateRoute>
        }
      />

      <Route
        path="/superpackage/edit/:id"
        element={
          <PrivateRoute>
            <EditSuperPackageModal />
          </PrivateRoute>
        }
      />

      <Route
        path="/superpackage/edit/procedures/:id"
        element={
          <PrivateRoute>
            <EditPackagesToSuperPackageModal />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
