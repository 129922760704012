import procedureTypes from './procedure.types';

export const initialState = {
  procedures: [],
  new_procedure_type: {},
  categories: [],
  single_category: {},
  proceduretype_categories: [],
  tests: [],
  single_procedure_test: {},
  price_types: [],
  ungrouped: []
};

const procedureReducers = (state = initialState, action) => {
  switch (action.type) {
    case procedureTypes.SET_ALL_PROCEDURE:
      return {
        ...state,
        procedures: action.payload
      };
    case procedureTypes.SAVE_PROCEDURE_TYPE:
      return {
        ...state,
        new_procedure_type: action.payload
      };
    case procedureTypes.SET_PROCEDURE_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case procedureTypes.SAVE_PROCEDURE_CATEGORIES:
      return {
        ...state,
        single_category: action.payload
      };
    case procedureTypes.SET_PROCEDURETYPE_CATEGORIES:
      return {
        ...state,
        proceduretype_categories: action.payload
      };
    case procedureTypes.SET_CATEGORY_TEST:
      return {
        ...state,
        tests: action.payload
      };
    case procedureTypes.SAVE_PROCEDURE_TEST:
      return {
        ...state,
        single_procedure_test: action.payload
      };
    case procedureTypes.SET_PRICE_TYPES:
      return {
        ...state,
        price_types: action.payload
      };
    case procedureTypes.SET_UNGROUPED_TEST:
      return {
        ...state,
        ungrouped: action.payload
      };
    default:
      return state;
  }
};

export default procedureReducers;
