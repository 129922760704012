import React from 'react';
import PropTypes from 'prop-types';
import Search from '../../assets/icons/search.svg';
import styles from './SearchBox.module.scss';

const SearchBox = ({ placeholder, name, onChange, searchValue, onSearch, dataTestId }) => {
  return (
    <div className={styles.search}>
      <input
        placeholder={placeholder}
        name={name}
        onChange={(e) => {
          onChange(e?.target && e?.target?.value);
        }}
        value={searchValue}
        data-testid={dataTestId}
      />
      <img
        src={Search}
        width="13.54px"
        height="13.54px"
        onClick={onSearch}
        alt="search"
        aria-hidden
      />
    </div>
  );
};

SearchBox.defaultProps = {
  onChange: null,
  onSearch: null,
  name: '',
  placeholder: '',
  searchValue: ''
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  searchValue: PropTypes.string,
  onSearch: PropTypes.func,
  dataTestId: PropTypes.string
};

export default SearchBox;
