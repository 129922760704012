import React from 'react';
import { Sidebar } from 'components/Sidebar';
import PropTypes from 'prop-types';
import styles from './layouts.module.scss';
import Header from 'components/Header/Header';

const DashboardLayout = ({ children }) => {
  return (
    <div className={styles.main__layout}>
      <Sidebar />
      <section className={styles.main__page}>
        <Header />
        <div>{children}</div>
      </section>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
