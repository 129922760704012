import reportTypes from './report.types';

export const createReportAction = (payload, cb, cbError) => ({
  type: reportTypes.CREATE_REPORT,
  payload,
  cb,
  cbError
});

export const getReportsAction = (payload, cb, cbError) => ({
  type: reportTypes.FETCH_REPORTS,
  payload,
  cb,
  cbError
});

export const getSingleReportAction = (payload, cb, cbError) => ({
  type: reportTypes.FETCH_SINGLE_REPORT,
  payload,
  cb,
  cbError
});

export const getAllTestAction = (cb, cbError) => ({
  type: reportTypes.FETCH_TESTS,
  cb,
  cbError
});

export const editReportAction = (payload, cb, cbError) => ({
  type: reportTypes.EDIT_TESTS,
  payload,
  cb,
  cbError
});

export const deleteSingleReportAction = (payload, cb, cbError) => ({
  type: reportTypes.DELETE_SINGLE_REPORT,
  payload,
  cb,
  cbError
});
