import React from 'react';
import CenterDetailForm from 'container/centerFormLayout/centerDetailForm';
import CenterFormLayout from 'components/Layout/centerFormLayout';

export const CreateCenter = () => {
  return (
    <div>
      <CenterFormLayout
        currentPage={1}
        totalPage={3}
        backTheme="tertiary"
        backSize="sm"
        closeTheme="tertiary"
        closeSize="sm"
        title="Create Center"
        caption="Enter center details"
      >
        <CenterDetailForm />
      </CenterFormLayout>
    </div>
  );
};
