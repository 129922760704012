import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const ReportsPage = lazy(() => import('pages/Reports/Reports'));
export const CreateReportPage = lazy(() => import('container/clinicalReport/createReport'));
export const EditReportPage = lazy(() => import('container/clinicalReport/editReport'));
export const ViewReportPage = lazy(() => import('container/clinicalReport/viewReport'));
export const FinancialReportingPage = lazy(() => import('pages/Reporting/FinancialReporting'));
export const CentersBookedRevenueDetailsPage = lazy(() =>
  import('pages/Reporting/CentersBookedRevenueDetailsPage')
);
export const CentersCollectedRevenueDetailsPage = lazy(() =>
  import('pages/Reporting/CentersCollectedRevenueDetailsPage')
);

export default function ReportRoutes() {
  return (
    <Routes>
      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <ReportsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/reports/create/:code"
        element={
          <PrivateRoute>
            <CreateReportPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/edit/:id/:page"
        element={
          <PrivateRoute>
            <EditReportPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/view/:id/:page"
        element={
          <PrivateRoute>
            <ViewReportPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/financial-report"
        element={
          <PrivateRoute>
            <FinancialReportingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/financial-report/collected-revenue/:id"
        element={
          <PrivateRoute>
            <CentersCollectedRevenueDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/financial-report/booked-revenue/:id"
        element={
          <PrivateRoute>
            <CentersBookedRevenueDetailsPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
