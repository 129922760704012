/* eslint-disable react/prop-types */
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import styles from '../../websiteManagement.module.scss';
import { useDispatch } from 'react-redux';
import { getUngroupedTestAction } from 're-ducks/procedure';
import { getAllPackagesAction } from 're-ducks/packages';
import { toast } from 'react-toastify';
import {
  deleteSubCategoryProcedureAction,
  deleteWebsiteCategoryOrSubCategoryAction,
  editWebsiteCategoriesAction,
  fetchWebsiteCategoryDetailsAction
} from 're-ducks/websiteManagement';
import { removeEmpty } from 'utils/removeEmptyValues';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateCategoryFormSchema } from 'utils/validation';
import FormFields from './formFields';

const EditCategoryForm = ({ data, procedures, setProcedures }) => {
  const dispatch = useDispatch();
  const [procedureType, setProcedureType] = useState('packages');
  const [allProcedures, setAllProcedures] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); // This holds the items added to the array
  const [selectedItem, setSelectedItem] = useState(null); // This holds the currently selected item before adding to the array
  const [displayName, setDisplayName] = useState('');
  const [packageDescription, setPackageDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(
      getUngroupedTestAction(
        {},
        (res) => {
          setAllProcedures(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getAllPackagesAction(
        { params: { type: 'Self Pay' } },
        (res) => {
          setAllPackages(res?.packages);
        },
        (err) => {
          toast.error(err);
        }
      )
    );
  }, []);

  useEffect(() => {
    if (procedures && procedureType === 'tests') {
      const result = Object.values(allProcedures)
        .map((item) => {
          const matchedCode = procedures?.find((check) => check.procedure_code === item.unique_id);
          if (matchedCode) {
            return {
              ...item,
              label: item.name,
              value: matchedCode.procedure_code,
              public_name: matchedCode.public_name
            };
          }
        })
        .filter(Boolean);
      setSelectedItems(result);
    } else {
      const result = Object.values(allPackages)
        .map((item) => {
          const matchedCode = data?.packages?.find((check) => check.package_id === item.id);
          if (matchedCode) {
            return {
              ...item,
              id: matchedCode?.id,
              label: item.name,
              value: matchedCode.package_id,
              public_name: matchedCode.public_name,
              description: matchedCode.description
            };
          }
        })
        .filter(Boolean);
      setSelectedItems(result);
    }
  }, [data, procedureType, allPackages, allProcedures]);

  const procedureCodes = procedures?.map((i) => i.procedure_code);

  const handleRemove = (item) => {
    if (procedureType === 'tests' && procedureCodes?.includes(item?.value)) {
      dispatch(
        deleteSubCategoryProcedureAction(
          {
            procedures: [item?.value],
            category_id: params?.id,
            delete_from: 'category'
          },
          () => {
            setSelectedItems(
              selectedItems?.filter((procedure) => procedure?.value !== item?.value)
            );
            dispatch(
              fetchWebsiteCategoryDetailsAction(
                {
                  id: params?.id
                },
                (res) => {
                  setProcedures(res?.data?.procedures);
                },
                () => {}
              )
            );
            toast.success('Procedure has been deleted');
          },
          (err) => {
            toast.error(err);
          }
        )
      );
    } else if (item.id) {
      dispatch(
        deleteWebsiteCategoryOrSubCategoryAction(
          {
            delete: 'sub-category',
            sub_category_id: item?.id
          },
          () => {
            setSelectedItems(
              selectedItems?.filter((procedure) => procedure?.value !== item?.value)
            );
            toast.success('Package has been deleted');
          },
          (err) => {
            toast.error(err);
          }
        )
      );
    } else {
      setSelectedItems(selectedItems?.filter((procedure) => procedure?.value !== item?.value));
    }
  };

  const handleAddToCategory = () => {
    if (selectedItem) {
      const newEntry = {
        ...selectedItem,
        public_name: displayName,
        description: packageDescription
      };

      setSelectedItems([...selectedItems, newEntry]);
      setDisplayName(''); // Clear display name
      setPackageDescription('');
      setSelectedItem(null); // Clear the selected item
    }
  };

  const handleCreateCategory = (values) => {
    const payload = {
      ...values,
      category_id: params.id,
      packages:
        values?.add === 'packages'
          ? selectedItems?.map((item) => ({
              package_id: item.value,
              public_name: item.public_name,
              description: item.description
            }))
          : '',
      procedures:
        values?.add === 'tests'
          ? selectedItems?.map((item) => ({
              procedure_code: item.value,
              public_name: item.public_name
            }))
          : ''
    };
    setLoading(true);
    dispatch(
      editWebsiteCategoriesAction(
        removeEmpty(payload),
        (res) => {
          setLoading(false);
          toast.success(res.data.message);
          navigate('/website-management');
        },
        (err) => {
          toast.error(err);
          setLoading(false);
        }
      )
    );
  };

  return (
    <Formik
      initialValues={{
        name: data?.name || {},
        show_in: data?.show_in,
        description: data?.description,
        add: 'packages'
      }}
      onSubmit={handleCreateCategory}
      enableReinitialize
      validationSchema={CreateCategoryFormSchema}
    >
      {(formik) => (
        <div className={styles.create__form}>
          <Form>
            <FormFields
              formik={formik}
              procedureType={procedureType}
              setProcedureType={setProcedureType}
              handleAddToCategory={handleAddToCategory}
              selectedItems={selectedItems}
              allPackages={allPackages}
              allProcedures={allProcedures}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              displayName={displayName}
              setDisplayName={setDisplayName}
              packageDescription={packageDescription}
              setPackageDescription={setPackageDescription}
              handleRemove={handleRemove}
            />

            <Button
              theme="primary"
              size="md"
              type="submit"
              disabled={!formik.isValid || loading || selectedItems?.length === 0}
              loading={loading}
            >
              Save category
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default EditCategoryForm;
