/* eslint-disable no-undef */
import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { app } from 'firebaseNotfications/firebase';
import { useNavigate } from 'react-router-dom';
import NotificationIcon from 'assets/icons/notification.svg';
import OldNotificationIcon from 'assets/icons/notification-inactive.svg';
import styles from './notifications.module.scss';
import PropTypes from 'prop-types';
import { onValue, ref, getDatabase } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileAction } from 're-ducks/users';
import placeholder from 'assets/icons/table-placeholder.svg';

export const Notification = ({ caption, date, time }) => (
  <div className={styles.single_notification}>
    <p>{caption}</p>
    <p className={styles.single_notification__date}>{date}</p>
    <p className={styles.single_notification__time}>{time}</p>
  </div>
);

Notification.propTypes = {
  caption: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string
};

const EmptyNotifications = () => {
  return (
    <div className={styles.emptyNotifications}>
      <img src={placeholder} alt="placeholder" />
      <p>No Notifications</p>
    </div>
  );
};

const Notifications = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notifyArr, setNotifyArr] = useState([]);
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getProfileAction(
        () => {},
        () => {}
      )
    );
  }, []);

  const db = getDatabase(app);

  useEffect(() => {
    onValue(
      ref(db, `messaging/${auth?.signIn?.id}/notification`),
      (snapshot) => {
        const data = snapshot.val();
        const res = Object.values(data || {})?.map((item) => {
          return item;
        });
        const sortArray = res?.sort((a, b) => new Date(b.date) - new Date(a.date));
        setAllNotifications(sortArray?.slice(0, 3));
        setNotifyArr(sortArray?.slice(0, 10));
      },
      {
        onlyOnce: false
      }
    );
  }, [auth?.signIn?.id, db, getDatabase, onValue]);

  const viewedStatus = notifyArr?.map((i) => i.seen);

  return (
    <div>
      <Menu as="div">
        <div>
          <Menu.Button>
            {viewedStatus.includes(false) ? (
              <img src={NotificationIcon} alt="notifications" />
            ) : (
              <img src={OldNotificationIcon} alt="oldNotifications" />
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={styles.notifications__drop}>
            {allNotifications?.length !== 0 ? (
              allNotifications?.map((i) => {
                return <Notification caption={i.body} date={i.date} time={i.time} />;
              })
            ) : (
              <EmptyNotifications />
            )}
            {allNotifications?.length ? (
              <p
                className={styles.notifications__view}
                aria-hidden
                data-testid="notificationId"
                onClick={() => navigate('/notifications')}
              >
                View all Notifications
              </p>
            ) : null}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Notifications;