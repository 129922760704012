import centerTypes from './centers.types';

export const initialState = {
  centers: [],
  branch: {}
};

const centersReducer = (state = initialState, action) => {
  switch (action.type) {
    case centerTypes.SET_BRANCHES:
      return {
        ...state,
        branch: action.payload
      };
    default:
      return state;
  }
};

export default centersReducer;
