/* eslint-disable react/prop-types */
import styles from '../packages.module.scss';
import Close from 'assets/icons/closeModal.svg';

const PackageSummary = ({ allProcedureTests, setAllProcedureTests, packagename }) => {
  const removeTests = (test) => {
    setAllProcedureTests((prevTest) => prevTest?.filter((item) => item !== test));
  };
  return (
    <div className={styles.packages_create__packageProcedures__selector}>
      {packagename ? '' : <h3>Procedures</h3>}
      <div className={styles.packages_create__packageProcedures__container}>
        <div>
          {allProcedureTests?.length === 0 ? (
            <p className={styles.packages_create__packageProcedures__empty}>
              {packagename
                ? 'No package name selected yet'
                : 'No procedures have been selected yet'}
            </p>
          ) : (
            <div className={styles.packages_create__packageProcedures__container__flex}>
              {allProcedureTests
                ?.filter((item) => item?.label?.length !== 0 && item !== undefined)
                .map((i) => {
                  return (
                    <div className={styles.packages_create__packageProcedures__item}>
                      <div>
                        {i?.label}
                        <br />
                        {packagename ? '' : <span>{i?.value}</span>}
                      </div>
                      <div aria-hidden className={styles.packages_create__packageProcedures__icon}>
                        <img
                          width="19"
                          height="19"
                          src={Close}
                          alt=""
                          onClick={() => removeTests(i)}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageSummary;
