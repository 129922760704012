/**
 * @name: useLocationPath
 * @description: A useHook that renders the location of a page
 */
import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';

const useLocationPath = ({ setPage, pathname, schedule }) => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [locationState, setLocationState] = useState();

  useEffect(() => {
    if (location?.state) {
      setPage(schedule ? location?.state?.pageNum : location?.state?.page);
      setLocationState(true);
      history.replace({ pathname: location.pathname, state: {} });
    }
  }, [location?.state]);

  return { locationState };
};

export default useLocationPath;
