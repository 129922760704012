import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const PatientsPage = lazy(() => import('pages/Patients/Patients'));
export const PatientDetailsPage = lazy(() => import('container/patient/patientDetails'));
export const ViewMorePage = lazy(() => import('pages/Patients/ViewMore'));

export default function PatientRoutes() {
  return (
    <Routes>
      <Route
        path="/patients"
        element={
          <PrivateRoute>
            <PatientsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/patients/:id/:page"
        element={
          <PrivateRoute>
            <PatientDetailsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/patients/:id/view-more"
        element={
          <PrivateRoute>
            <ViewMorePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
