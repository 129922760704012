import RightSideModal from 'components/Modal/RightSideModal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../websiteManagement.module.scss';
import DatePickerInput from 'components/FormInput/DatePickerInput';
import Button from 'components/Button/Button';
import moment from 'moment';
import RadioButton from 'components/RadioButton';

const FilterWebsiteCenter = ({ isOpen, isClose, setFilteredData, setPage }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [centerType, setCenterType] = useState('');

  const handleFilter = () => {
    setFilteredData({
      from: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      to: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      center_type: centerType ? centerType : ''
    });
    isClose();
    setPage(1);
  };
  return (
    <RightSideModal isOpen={isOpen} isClose={isClose}>
      <div>
        <div className={styles.filterModal__header}>
          <h2>Filter</h2>
          <button
            className={styles.filterModal__header__cancelButton}
            onClick={() => {
              isClose();
              setPage(1);
            }}
          >
            X
          </button>{' '}
        </div>
        <p className={styles.filterModal__title}>Date Range</p>
        <div className={styles.filterModal__dateRange}>
          <DatePickerInput
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="dd mm yyyy"
          />
          <DatePickerInput
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="dd mm yyyy"
          />
        </div>

        <div style={{ marginTop: '32px' }}>
          <p className={styles.filterModal__title}>Center type</p>
          <RadioButton
            text="Full Service"
            value="Full Service"
            name="center_type"
            onChange={(e) => setCenterType(e.target.value)}
          />
          <RadioButton
            text="Affiliate clinic"
            value="Affiliate"
            name="center_type"
            onChange={(e) => setCenterType(e.target.value)}
          />
        </div>

        <div className={styles.filterModal__button}>
          <Button size="sm" type="submit" theme="oblige" onClick={() => isClose()}>
            Cancel
          </Button>
          <Button size="sm" type="submit" theme="primary" onClick={() => handleFilter()}>
            Filter
          </Button>
        </div>
      </div>
    </RightSideModal>
  );
};

FilterWebsiteCenter.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.object,
  setFilteredData: PropTypes.any,
  setPage: PropTypes.func
};
export default FilterWebsiteCenter;
