import { API } from 'const';
import queryFormatter from 'helpers/queryFormatter';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import claimTypes from './claims.types';

export function* getClaims(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(
        data?.data
          ? `${API.CLAIMS}?${queryFormatter(data.params)}&${data?.data}`
          : `${API.CLAIMS}?${queryFormatter(data.params)}`
      )
    );
    if (res.status === 200) {
      yield put({ type: claimTypes.SET_ALL_CLAIMS, payload: res.data.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSingleClaim(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.CLAIMS}/${data?.id}`));
    if (res.status === 200) {
      yield put({ type: claimTypes.SET_SINGLE_CLAIM_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fileClaims(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.CLAIMS}`, data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* makePayment(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.PARTNERS}/payments`, data));
    if (res.status === 201) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchClaimsSaga() {
  yield takeEvery(claimTypes.GET_ALL_CLAIMS, getClaims);
  yield takeEvery(claimTypes.GET_SINGLE_CLAIM_DETAILS, getSingleClaim);
  yield takeEvery(claimTypes.FILE_CLAIMS, fileClaims);
  yield takeEvery(claimTypes.MAKE_PAYMENT, makePayment);
}

export default watchClaimsSaga;
