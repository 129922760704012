import CenterFormLayout from 'components/Layout/centerFormLayout';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditCategoryForm from './editCategoryForm';
import { fetchWebsiteCategoryDetailsAction } from 're-ducks/websiteManagement';
import { useDispatch } from 'react-redux';
import FormLoader from 'components/Loading/FormLoader';

const EditCategory = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState();
  const [procedures, setProcedures] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    return navigate(-1);
  };

  const fetchWebsiteCategoryDetails = () => {
    setLoading(true);
    dispatch(
      fetchWebsiteCategoryDetailsAction(
        {
          id: params?.id
        },
        (res) => {
          setLoading(false);
          setData(res.data);
          setProcedures(res?.data?.procedures);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchWebsiteCategoryDetails();
  }, []);
  return (
    <div>
      <CenterFormLayout
        backTheme="tertiary"
        backSize="sm"
        closeTheme="tertiary"
        closeSize="sm"
        title="Edit Category"
        onClose={handleClose}
      >
        {loading ? (
          <FormLoader />
        ) : (
          <EditCategoryForm data={data} procedures={procedures} setProcedures={setProcedures} />
        )}
      </CenterFormLayout>
    </div>
  );
};

export default EditCategory;
