const partnerTypes = {
  CREATE_PARTNER: 'CREATE_PARTNER',
  GET_PARTNERSHIP_TYPE: 'GET_PARTNERSHIP_TYPE',
  CREATE_PARTNERS: 'CREATE_PARTNERS',
  GET_ALL_PARTNERS: 'GET_ALL_PARTNERS',
  GET_PARTNER_DETAILS: 'GET_PARTNER_DETAILS',
  GET_PARTNER_TRANSACTION_DETAILS: 'GET_PARTNER_TRANSACTION_DETAILS',
  EDIT_PARTNER_DETAILS: 'EDIT_PARTNER_DETAILS',
  DELETE_PARTNER: 'DELETE_PARTNER',
  UPDATE_PARTNER_STATUS: 'UPDATE_PARTNER_STATUS:',
  GET_PARTNER_OUTSTANDING_TRANSACTION_DETAILS: 'GET_PARTNER_OUTSTANDING_TRANSACTION_DETAILS',
  CREATE_PARTNERS_PAYMENT: 'CREATE_PARTNERS_PAYMENT',
  GET_PARTNER_PATIENT_DETAILS: 'GET_PARTNER_PATIENT_DETAILS',
  CREATE_INVOICE: 'CREATE_INVOICE',
  GET_INVOICE_DETAILS: 'GET_INVOICE_DETAILS',
  GET_PARTNER_INVOICES: 'GET_PARTNER_INVOICES'
};

export default partnerTypes;
