import React from 'react';
import PropTypes from 'prop-types';
import styles from 'container/rolesManagement/roles.module.scss';
import style from '../packages.module.scss';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader';

const StatusModal = ({ isOpen, isClose, onSave, loadingBtn, superpackage, statusCaption }) => {
  const status = statusCaption === 'active' ? 'Deactivate' : 'Activate';
  return (
    <Modal isOpen={isOpen} isClose={isClose} width={464} height={200}>
      <div>
        <h2 className={styles.modal__header}>
          {statusCaption === 'active' ? 'Deactivate' : 'Activate'}{' '}
          {superpackage ? 'Super-package' : 'Package'}
        </h2>
        <div className={styles.modal__caption}>
          <p>{`Are you sure you want to ${
            statusCaption === 'active' ? 'deactivate' : 'activate'
          } this ${superpackage ? 'super-package' : 'package'}?`}</p>
        </div>
        <div className={style.status__modal__button}>
          <button onClick={isClose} className={style.status__modal__button_cancel}>
            Cancel
          </button>

          <button onClick={onSave} className={style.status__modal__button_save}>
            {loadingBtn ? <Loader color="white" /> : status}
          </button>
        </div>
      </div>
    </Modal>
  );
};

StatusModal.propTypes = {
  isOpen: PropTypes.func,
  statusCaption: PropTypes.string,
  isClose: PropTypes.object,
  onSave: PropTypes.func,
  loadingBtn: PropTypes.bool,
  heading: PropTypes.string,
  caption: PropTypes.string,
  data: PropTypes.any,
  create: PropTypes.bool,
  superpackage: PropTypes.bool
};

export default StatusModal;
