const resultTrackingTypes = {
  GET_ALL_RESULT_TRACKING: 'GET_ALL_RESULT_TRACKING',
  GET_SINGLE_RESULT_TRACKING: 'GET_SINGLE_RESULT_TRACKING',
  GET_MULTIPLE_RESULTS: 'GET_MULTIPLE_RESULTS',
  SET_MULTIPLE_RESULTS: 'SET_MULTIPLE_RESULTS',
  SET_ALL_RESULT_TRACKING: 'SET_ALL_RESULT_TRACKING',
  UPDATE_RESULT_TRACKING: 'UPDATE_RESULT_TRACKING',
  COMPLETE_PATIENT_RESULT: 'COMPLETE_PATIENT_RESULT'
};

export default resultTrackingTypes;
