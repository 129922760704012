import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import styles from '../../container/layouts/layouts.module.scss';
import Close from 'assets/icons/close.svg';
import BackArrow from 'assets/icons/back.svg';
import PropTypes from 'prop-types';

const CenterFormLayout = ({
  children,
  backTheme,
  backSize,
  closeTheme,
  closeSize,
  title,
  caption,
  currentPage,
  totalPage,
  onBackClick,
  onClose
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className={styles.FormLayoutContainer}>
      <div className={styles.formButton}>
        <Button
          theme={backTheme}
          size={backSize}
          onClick={onBackClick ? onBackClick : () => navigate(-1)}
        >
          <img src={BackArrow} alt="back" />
          &nbsp; Back
        </Button>
        <Button theme={closeTheme} size={closeSize} onClick={onClose ? onClose : handleClose}>
          Close &nbsp;
          <img src={Close} alt="close" />
        </Button>
      </div>
      <section className={styles.main__form}>
        <p className={styles.main__title}>
          {currentPage}
          {location.pathname.includes('create-center') ||
          location.pathname.includes('assign-admin') ||
          location.pathname.includes('center-procedure') ||
          location.pathname.includes('assign-procedure') ? (
            <span className={styles.main__caption}>/{totalPage}</span>
          ) : (
            ''
          )}
        </p>
        <p className={styles.main__title}>{title}</p>
        <p className={styles.main__caption}>{caption}</p>
        <div className={styles.wrapper}>{children}</div>
      </section>
    </div>
  );
};

CenterFormLayout.propTypes = {
  children: PropTypes.ReactNode,
  backSize: PropTypes.string.isRequired,
  backTheme: PropTypes.string.isRequired,
  closeSize: PropTypes.string.isRequired,
  closeTheme: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  onBackClick: PropTypes.func,
  onClose: PropTypes.func
};

export default CenterFormLayout;
