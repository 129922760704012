import Modal from 'components/Modal/Modal';
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'services/axios';
import { useNavigate } from 'react-router-dom';
import styles from './header.module.scss';

const LogOutModal = ({ isOpen, isClose }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('acessToken');
    localStorage.removeItem('persist:root');
    navigate('/auth/sign-in');
  };
  return (
    <Modal isOpen={isOpen} isClose={isClose} width={464} height={200}>
      <div>
        <h2 className={styles.logout__header}>Do you want to Logout?</h2>
        <div className={styles.logout__caption}>
          <p>By logging out you will be required to provide your email and password to login. </p>
        </div>
        <div className={styles.logout__button}>
          <button className={styles.logout__button_cancel} onClick={isClose}>
            Cancel
          </button>
          <button
            className={styles.logout__button_logout}
            data-testid="logout-button"
            onClick={() => handleLogout()}
          >
            Logout
          </button>
        </div>
      </div>
    </Modal>
  );
};

LogOutModal.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.object
};

export default LogOutModal;
