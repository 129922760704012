import React from 'react';
import PropTypes from 'prop-types';
import styles from './formloader.module.scss';

const FormLoader = ({ viewProfile, amount }) => (
  <div className={styles.skeleton}>
    <div className={viewProfile ? styles.skeleton__circle : ''}></div>
    {amount ? (
      <div>
        <div className={styles.skeleton__amount}>
          <div className={styles.skeleton__flex__child}></div>
          <div className={styles.skeleton__flex__child}></div>
        </div>
        <div className={styles.skeleton__form}></div>
        <div className={styles.skeleton__form}></div>
      </div>
    ) : (
      <div>
        <div className={styles.skeleton__form}></div>
        <div className={styles.skeleton__form}></div>
        <div className={styles.skeleton__flex}>
          <div className={styles.skeleton__flex__child}></div>
          <div className={styles.skeleton__flex__child}></div>
        </div>
        <div className={styles.skeleton__form}></div>
        <div className={styles.skeleton__form}></div>
        <div className={styles.skeleton__form}></div>
        <div className={styles.skeleton__form}></div>
      </div>
    )}
  </div>
);

FormLoader.propTypes = {
  viewProfile: PropTypes.bool,
  amount: PropTypes.bool
};

export default FormLoader;
