import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export const PartnersPage = lazy(() => import('pages/Partners/Partners'));
export const PartnerDetailPage = lazy(() => import('pages/Partners/PartnerDetail'));
export const CreatePartnerPage = lazy(() => import('container/partners/createPartner'));
export const PartnerTypePage = lazy(() => import('container/partners/createPartnerType'));
export const EditPartnerPage = lazy(() => import('container/partners/editPartner'));

export default function PartnerRoutes() {
  return (
    <Routes>
      <Route
        path="/partners"
        element={
          <PrivateRoute>
            <PartnersPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/partners/:id/:code/:page/:type"
        element={
          <PrivateRoute>
            <PartnerDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-partner"
        element={
          <PrivateRoute>
            <CreatePartnerPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/partner-type"
        element={
          <PrivateRoute>
            <PartnerTypePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-partner/:id/:page/:type"
        element={
          <PrivateRoute>
            <EditPartnerPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
