import { AppRouter } from '../router';
import { ToastContainer } from 'react-toastify';
import { CustomBrowserRouter } from 'utils/customBrowserRouter';
import ScrollToTop from '../components/scrollToTheTop/scrollToTheTop';

function App() {
  return (
    <CustomBrowserRouter>
      <ScrollToTop>
        <AppRouter />
        <ToastContainer theme="colored" autoClose={3000} />
      </ScrollToTop>
    </CustomBrowserRouter>
  );
}

export default App;
