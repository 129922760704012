const financialTypes = {
  GET_CARD_ANALYTICS: 'GET_CARD_ANALYTICS',
  SET_CARD_ANALYTICS: 'SET_CARD_ANALYTICS',
  GET_CENTERS_COLLECTED_REVENUES: 'GET_CENTERS_COLLECTED_REVENUES',
  SET_CENTERS_COLLECTED_REVENUES: 'SET_CENTERS_COLLECTED_REVENUES',
  GET_CENTERS_BOOKED_REVENUES: 'GET_CENTERS_BOOKED_REVENUES',
  SET_CENTERS_BOOKED_REVENUES: 'SET_CENTERS_BOOKED_REVENUES',
  GET_CHANNELS_COLLECTED_REVENUES: 'GET_CHANNELS_COLLECTED_REVENUES',
  SET_CHANNELS_COLLECTED_REVENUES: 'SET_CHANNELS_COLLECTED_REVENUES',
  GET_CHANNELS_BOOKED_REVENUES: 'GET_CHANNELS_BOOKED_REVENUES',
  SET_CHANNELS_BOOKED_REVENUES: 'SET_CHANNELS_BOOKED_REVENUES',
  GET_GRAPH_TREND: 'GET_GRAPH_TREND',
  SET_GRAPH_TREND: 'SET_GRAPH_TREND',
  GET_COLLECTED_REVENUE_TOPLINE: 'GET_COLLECTED_REVENUE_TOPLINE',
  SET_COLLECTED_REVENUE_TOPLINE: 'SET_COLLECTED_REVENUE_TOPLINE',
  GET_BOOKED_REVENUE_TOPLINE: 'GET_BOOKED_REVENUE_TOPLINE',
  SET_BOOKED_REVENUE_TOPLINE: 'SET_BOOKED_REVENUE_TOPLINE',
  GET_CHANNEL_COLLECTED_REVENUES_DETAIL: 'GET_CHANNEL_COLLECTED_REVENUES_DETAIL',
  SET_CHANNEL_COLLECTED_REVENUES_DETAIL: 'SET_CHANNEL_COLLECTED_REVENUES_DETAIL',
  GET_CHANNEL_BOOKED_REVENUES_DETAIL: 'GET_CHANNEL_BOOKED_REVENUES_DETAIL',
  SET_CHANNEL_BOOKED_REVENUES_DETAIL: 'SET_CHANNEL_BOOKED_REVENUES_DETAIL'
};

export default financialTypes;
