import { all } from 'redux-saga/effects';
import { watchAuthSaga } from './auth';
import { watchProfileSaga } from './users';
import { watchRoleSaga } from './roles';
import { watchCenterSaga } from './centers';
import { watchProcedureSaga } from './procedure';
import { watchPackagesSaga } from './packages';
import { watchPartnerSaga } from './partner';
import { watchAuditSaga } from './audit';
import { watchPatientSaga } from './patient';
import { watchReferralSaga } from './referrals';
import { watchReportSaga } from './report';
import { watchFinancialSaga } from './financialReport';
import { watchNotificationSaga } from './notification';
import { watchDashboardSaga } from './dashboard';
import { watchSupportSaga } from './support';
import { watchClaimsSaga } from './claims';
import { watchResultTrackingSaga } from './resultTracking';
import { watchRebateSaga } from './rebate';
import { watchOnlineBookingsSaga } from './onlineBooking';
import { watchWebsiteManagementSaga } from './websiteManagement';

function* rootSaga() {
  yield all([
    watchAuthSaga(),
    watchProfileSaga(),
    watchRoleSaga(),
    watchCenterSaga(),
    watchProcedureSaga(),
    watchPackagesSaga(),
    watchPartnerSaga(),
    watchAuditSaga(),
    watchPatientSaga(),
    watchReferralSaga(),
    watchReportSaga(),
    watchFinancialSaga(),
    watchNotificationSaga(),
    watchDashboardSaga(),
    watchSupportSaga(),
    watchClaimsSaga(),
    watchResultTrackingSaga(),
    watchRebateSaga(),
    watchOnlineBookingsSaga(),
    watchWebsiteManagementSaga()
  ]);
}

export default rootSaga;
