import React from 'react';
import CenterFormLayout from 'components/Layout/centerFormLayout';
import AssignAdminForm from 'container/centerFormLayout/assignAdminForm';

export const AssignAdmin = () => {
  return (
    <div>
      <CenterFormLayout
        currentPage={2}
        totalPage={3}
        backTheme="tertiary"
        backSize="sm"
        closeTheme="tertiary"
        closeSize="sm"
        title="Assign Admin to centre"
        caption="Assign admin & other users to this center"
      >
        <AssignAdminForm />
      </CenterFormLayout>
    </div>
  );
};
