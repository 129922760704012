/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import styles from './Modal.module.scss';

const Modal = ({ isOpen, isClose, children, floatRight, width, height, minWidth, radius }) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={isClose}
        className={classNames(floatRight ? styles.modal2 : styles.modal)}
      >
        <Dialog.Overlay className={styles.overlay} />
        <div
          className={classNames(floatRight ? styles.modal__content2 : styles.modal__content)}
          style={{
            width: `${width}px`,
            minHeight: `${height}px`,
            minWidth: `${minWidth}px`,
            borderRadius: `${radius}px`
          }}
        >
          <div>{children}</div>
        </div>
      </Dialog>
    </div>
  );
};

Modal.defaultProps = {
  isClose: bool,
  width: ''
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.bool,
  children: PropTypes.node,
  floatRight: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  minWidth: PropTypes.number,
  radius: PropTypes.number
};

export default Modal;
