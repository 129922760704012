import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './input.module.scss';
import { ReactComponent as Calender } from '../../assets/icons/Calendar.svg';

const DatePickerInput = ({
  selected,
  className,
  dateFormat,
  placeholderText,
  type = 'text',
  label,
  onChange,
  id,
  maxDate,
  noBorder,
  name,
  spaceIcon,
  minDate,
  showYearDropdown,
  showMonthDropdown,
  scrollableYearDropdown,
  yearDropdownItemNumber,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  filterDate,
  highlightDates
}) => {
  return (
    <div>
      <label className={(styles.input__label, styles[`${type}`], className)} htmlFor={id}>
        {label && <span className={styles.input__label}>{label}</span>}
        <div className={styles.input_date}>
          <span>
            <DatePicker
              selected={selected}
              onChange={onChange}
              dateFormat={dateFormat}
              placeholderText={placeholderText}
              maxDate={maxDate}
              minDate={minDate}
              name={name}
              filterDate={filterDate}
              selectsStart={selectsStart}
              selectsEnd={selectsEnd}
              startDate={startDate}
              endDate={endDate}
              showYearDropdown={showYearDropdown}
              showMonthDropdown={showMonthDropdown}
              scrollableYearDropdown={scrollableYearDropdown}
              yearDropdownItemNumber={yearDropdownItemNumber}
              highlightDates={highlightDates}
              wrapperClassName={noBorder ? styles.no_border : styles.date_picker}
            />
          </span>
          <Calender className={spaceIcon ? styles.spaceCalendarIcon : styles.calendarIcon} />
        </div>
      </label>
    </div>
  );
};

DatePickerInput.propTypes = {
  selected: PropTypes.any,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  placeholderText: PropTypes.any,
  maxDate: PropTypes.any,
  noBorder: PropTypes.bool,
  spaceIcon: PropTypes.bool,
  minDate: PropTypes.any,
  showYearDropdown: PropTypes.bool,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  scrollableYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  yearDropdownItemNumber: PropTypes.number,
  filterDate: PropTypes.bool,
  highlightDates: PropTypes.any
};
export default DatePickerInput;
