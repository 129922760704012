const websiteManagementTypes = {
  CREATE_HOMEPAGE_CATEGORIES: 'CREATE_HOMEPAGE_CATEGORIES',
  CREATE_HOMEPAGE_SUBCATEGORIES: 'CREATE_HOMEPAGE_SUBCATEGORIES',
  ADD_WEBSITE_PROCEDURES: 'ADD_WEBSITE_PROCEDURES',
  CURATE_PACKAGES: 'CURATE_PACKAGES:',
  DELETE_SUBCATEGORY_PROCEDURE: 'DELETE_SUBCATEGORY_PROCEDURE',
  FETCH_CURATED_PACKAGES: 'FETCH_CURATED_PACKAGES',
  FETCH_ALL_CATEGORIES: 'FETCH_ALL_CATEGORIES',
  FETCH_CATEGORY_DETAILS: 'FETCH_CATEGORY_DETAILS',
  FETCH_SUBCATEGORY_DETAILS: 'FETCH_SUBCATEGORY_DETAILS',
  DELETE_CATEGORY_OR_SUBCATEGORY: 'DELETE_CATEGORY_OR_SUBCATEGORY',
  EDIT_WEBSITE_CATEGORIES: 'EDIT_WEBSITE_CATEGORIES',
  ORDER_CATEGORIES: 'ORDER_CATEGORIES'
};

export default websiteManagementTypes;
