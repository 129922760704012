import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import axios from 'services/axios';
import actionTypes from './auth.types';

export function* doSignIng(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.SIGNIN}?location=${data.location}`, data.params));
    if (res.status === 200) {
      axios.defaults.headers.common.Authorization = res.data.data.token;
      if (res?.data?.data?.user_branch_type === 'HQ_user') {
        localStorage.setItem('accessToken', res.data.data.token);
        localStorage.setItem('refreshToken', res.data.data.refreshToken);
      }
      yield put({ type: actionTypes.SET_SIGNIN_DATA, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doSendPasswordResetEmail(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(`${API.FORGOT}?location=HQ_user`, data));
    if (res.status === 200) {
      cb();
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doValidateOtp(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.post(API.VALIDATE_OTP, data));
    if (res.status === 200) {
      axios.defaults.headers.common.Authorization = res.data.data.token;
      localStorage.setItem('resetToken', res.data.data.token);
      cb();
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doSetPassword(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.patch(API.SET_PASSWORD, data));
    if (res.status === 200) {
      cb();
    }
  } catch (error) {
    const { cbError } = action;
    const alert = error?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* doSetNewPassword(action) {
  try {
    const { payload: data, cb } = action;
    const { token, password } = data;
    const res = yield call(() => api.put(`${API.SET_NEW_PASSWORD}?token=${token}`, { password }));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getRefreshToken(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.REFRESH_TOKEN}?refresh_token=${data?.refreshToken}`)
    );
    if (res.status === 200) {
      axios.defaults.headers.common.Authorization = res.data.data.token;
      localStorage.setItem('accessToken', res.data.data.token);
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* doSignOut(action) {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
    yield put(actionTypes.RESET_AUTH);
    action.cb();
  } finally {
    const { error } = action;
  }
}

function* watchAuthSaga() {
  yield takeEvery(actionTypes.DO_SIGNIN, doSignIng);
  yield takeEvery(actionTypes.DO_SEND_EMAIL, doSendPasswordResetEmail);
  yield takeEvery(actionTypes.DO_VALIDATE_OTP, doValidateOtp);
  yield takeEvery(actionTypes.DO_SET_PASSWORD, doSetPassword);
  yield takeEvery(actionTypes.DO_SET_NEW_PASSWORD, doSetNewPassword);
  yield takeEvery(actionTypes.GET_REFRESH_TOKEN, getRefreshToken);
  yield takeEvery(actionTypes.RESET_AUTH, doSignOut);
}

export default watchAuthSaga;
