import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const ClaimsPage = lazy(() => import('pages/Claims/Claims'));
export const SingleClaimPage = lazy(() => import('container/Claims/singleClaim'));

export default function ClaimRoutes() {
  return (
    <Routes>
      <Route
        path="/claims"
        element={
          <PrivateRoute>
            <ClaimsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/claims/detail/:id/:page"
        element={
          <PrivateRoute>
            <SingleClaimPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
