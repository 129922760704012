import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';

export const ResultTrackingPage = lazy(() => import('pages/ResultTracking/ResultTracking'));
export const PackageVisitDetails = lazy(() =>
  import('container/ResultTracking/PackageVisit/PackageVisitDetails')
);
export const PartnerVisitDetails = lazy(() =>
  import('container/ResultTracking/PartnerVisit/PartnerVisitDetails')
);

const ResultTrackingRoutes = () => {
  return (
    <Routes>
      <Route
        path="/result-tracking"
        element={
          <PrivateRoute>
            <ResultTrackingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/result-tracking/:patient_id/package-visit/:visit_id/:page/:type"
        element={
          <PrivateRoute>
            <PackageVisitDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/result-tracking/:patient_id/partner-visit/:visit_id/:page/:type"
        element={
          <PrivateRoute>
            <PartnerVisitDetails />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default ResultTrackingRoutes;
