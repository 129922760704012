import ReactPaginate from 'react-paginate';
import styles from './pagination.module.scss';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Pagination = ({ changeCurrentPage, forcePage, currentPage, totalPage, handlePageInput }) => {
  const [inputValue, setInputValue] = useState(currentPage);

  useEffect(() => {
    setInputValue(currentPage);
  }, [currentPage]);
  return (
    <div className={styles.pagination}>
      <p>
        Showing Page {currentPage} of {totalPage}
      </p>
      <div className={styles.pagination__container} style={{ width: totalPage === 1 && 'unset' }}>
        <ReactPaginate
          previousLabel={totalPage > 1 ? 'Prev' : null}
          nextLabel={totalPage > currentPage ? 'Next' : null}
          breakLabel="..."
          pageCount={totalPage}
          onPageChange={changeCurrentPage}
          marginPagesDisplayed={1}
          pageRange={1}
          activeClassName={styles.activePaginate}
          forcePage={forcePage}
        />
        {totalPage > 1 && (
          <input
            type="number"
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handlePageInput(inputValue);
              }
            }}
            value={inputValue}
          />
        )}
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  totalPage: 10,
  changeCurrentPage: null,
  forcePage: false,
  handlePageInput: () => {}
};

Pagination.propTypes = {
  totalPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeCurrentPage: PropTypes.func,
  forcePage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  handlePageInput: PropTypes.func
};

export default Pagination;
