const queryFormatter = (parameters) => {
    if(parameters){
      return Object?.keys(parameters)
        .map((key) => parameters[key] && `${key}=${parameters[key]}`)
        .join('&')
    } 
    
    return '&'
  };
  
  export default queryFormatter;