import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import styles from './Modal.module.scss';

const RightSideModal = ({ isOpen, isClose, children, width, height, minWidth }) => {
  return (
    <div>
      <Transition appear show as="div">
        <Dialog
          as="div"
          open={isOpen}
          onClose={() => {
            isClose();
          }}
          className={styles.rightModal}
        >
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={styles.rightModal__content}
              style={{ width: `${width}px`, minHeight: `${height}px`, minWidth: `${minWidth}px` }}
            >
              <div>{children}</div>
            </div>{' '}
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};

RightSideModal.defaultProps = {
  isClose: bool,
  children: React.ReactNode,
  width: ''
};

RightSideModal.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.any,
  children: PropTypes.node,
  floatRight: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  minWidth: PropTypes.number
};

export default RightSideModal;
