import { API } from 'const';
import { takeEvery, call } from 'redux-saga/effects';
import queryFormatter from 'helpers/queryFormatter';
import api from 'services/api';
import patientTypes from './patient.types';

export function* getAllPatient(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() => api.get(`${API.PATIENT}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getSinglePatient(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.get(`${API.PATIENT}/${data?.id?.id}/records?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* deleteSinglePatient(action) {
  try {
    const { payload: data, cb } = action;
    const res = yield call(() =>
      api.delete(`${API.PATIENT}/delete/${data?.id}?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchPatientSaga() {
  yield takeEvery(patientTypes.GET_PATIENTS, getAllPatient);
  yield takeEvery(patientTypes.GET_SINGLE_PATIENT, getSinglePatient);
  yield takeEvery(patientTypes.DELETE_PATIENT, deleteSinglePatient);
}

export default watchPatientSaga;
