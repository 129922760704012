import { API } from 'const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import centerTypes from './centers.types';
import queryFormatter from 'helpers/queryFormatter';

export function* getBranches(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.BRANCH}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      yield put({ type: centerTypes.SET_BRANCHES, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* createCenter(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.BRANCH}`, data));
    if (res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* assignAdmintoBranch(action) {
  try {
    const { cb, payload: data, id } = action;
    const res = yield call(() => api.put(`${API.BRANCH}/${id}/assign`, data));
    if (res.status === 200) {
      yield put({ type: centerTypes.SET_ADMIN, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message;
    cbError(alert);
  }
}

export function* getBranchDetails(action) {
  try {
    const { cb, id } = action;
    const res = yield call(() => api.get(`${API.BRANCH}/${id}`));
    if (res.status === 200) {
      yield put({ type: centerTypes.SET_SINGLE_BRANCH, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    if (cbError) {
      cbError(alert);
    }
  }
}

export function* editBranchDetails(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.BRANCH}/${data.id}/details`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updateBranchStatus(action) {
  try {
    const { cb, payload: data, id } = action;
    const res = yield call(() => api.patch(`${API.BRANCH}/${id}/status`, data));
    if (res.status === 200) {
      yield put({ type: centerTypes.SET_BRANCH_STATUS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchBranchUserDetails(action) {
  try {
    const { id, branch_id, cb } = action;
    const res = yield call(() => api.get(`${API.BRANCH}/${branch_id}/admin/${id}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updateBranchAdmin(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.ROLE}/edit-admin`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* ReassignAdmin(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.patch(`${API.BRANCH}/reassign-admin`, data?.data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getCenterProcedure(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.FETCH_PROCEDURE}/${data.code}/tests?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchCentreRoles(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.ROLE}/type?type=${data.type}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* getWebsiteCenters(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.get(`${API.WEBSITECENTERS}?${queryFormatter(data.params)}`));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* createWebsiteCenter(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.post(`${API.CREATEWEBSITECENTERS}`, data));
    if (res.status === 201) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}
export function* getWebsiteCenterDetails(action) {
  try {
    const { cb, id } = action;
    const res = yield call(() => api.get(`${API.CENTER}/${id}/center-details`));
    if (res.status === 200) {
      yield put({ type: centerTypes.GET_WEBSITE_CENTER_DETAILS, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    if (cbError) {
      cbError(alert);
    }
  }
}
export function* deleteWebsiteCenter(action) {
  try {
    const { cb, id } = action;
    const res = yield call(() => api.delete(`${API.WEBCENTER}/${id}`));
    if (res.status === 200) {
      yield put({ type: centerTypes.DELETE_WEBSITE_CENTER, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    if (cbError) {
      cbError(alert);
    }
  }
}
export function* editWebsiteCenterDetails(action) {
  try {
    const { cb, payload, id } = action;
    const res = yield call(() => api.patch(`${API.WEBCENTER}/${id}`, payload));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchCenterSaga() {
  yield takeEvery(centerTypes.GET_BRANCHES, getBranches);
  yield takeEvery(centerTypes.CREATE_BRANCH, createCenter);
  yield takeEvery(centerTypes.ASSIGN_ADMIN, assignAdmintoBranch);
  yield takeEvery(centerTypes.GET_SINGLE_BRANCH, getBranchDetails);
  yield takeEvery(centerTypes.EDIT_BRANCH_DETAIL, editBranchDetails);
  yield takeEvery(centerTypes.UPDATE_BRANCH_STATUS, updateBranchStatus);
  yield takeEvery(centerTypes.FETCH_BRANCH_USER_DETAILS, fetchBranchUserDetails);
  yield takeEvery(centerTypes.UPDATE_BRANCH_ADMIN, updateBranchAdmin);
  yield takeEvery(centerTypes.GET_CENTER_PROCEDURE, getCenterProcedure);
  yield takeEvery(centerTypes.FETCH_CENTRE_ROLE, fetchCentreRoles);
  yield takeEvery(centerTypes.RE_ASSIGN_ADMIN, ReassignAdmin);
  yield takeEvery(centerTypes.GET_WEBSITE_CENTERS, getWebsiteCenters);
  yield takeEvery(centerTypes.CREATE_WEBSITE_CENTER, createWebsiteCenter);
  yield takeEvery(centerTypes.GET_WEBSITE_CENTER_DETAILS, getWebsiteCenterDetails);
  yield takeEvery(centerTypes.DELETE_WEBSITE_CENTER, deleteWebsiteCenter);
  yield takeEvery(centerTypes.EDIT_WEBSITE_CENTER_DETAILS, editWebsiteCenterDetails);
}

export default watchCenterSaga;
