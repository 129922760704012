import roleTypes from './roles.types';

export const getAllRolesAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_ROLES,
  payload,
  cb,
  cbError
});

export const getAllPermissionsAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_PERMISSIONS,
  payload,
  cb,
  cbError
});

export const createRoleAction = (payload, cb, cbError) => ({
  type: roleTypes.CREATE_ROLE,
  payload,
  cb,
  cbError
});

export const fetchSingleRoleAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_SINGLE_ROLE,
  payload,
  cb,
  cbError
});

export const editSingleRoleAction = (payload, cb, cbError) => ({
  type: roleTypes.GET_EDITED_ROLE,
  payload,
  cb,
  cbError
});

export const updateRoleAction = (payload, cb, cbError) => ({
  type: roleTypes.UPDATE_ROLE_STATUS,
  payload,
  cb,
  cbError
});
