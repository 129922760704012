import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { affiliateSchema, fullServiceSchema } from 'utils/validation';
import { handleName } from 'helpers/nameChange';
import Input from 'components/FormInput/FormInput';
import Button from 'components/Button/Button';
import { states } from 'const/states';
import Select from 'components/Select';
import styles from './centerForm.module.scss';
import {
  createWebsiteCentersAction,
  getBranchesAction,
  getSingleBranchAction
} from 're-ducks/centers';
import { toast } from 'react-toastify';
import ReactSelect from 'components/Select/ReactSelect';
import Clock from '../../assets/icons/clock.svg';
import ImageFile from 'container/websiteManagement/WebsiteCenters/image';
import FormDetails from 'container/websiteManagement/WebsiteCenters/FormDetails';
import WorkingHoursModal from 'container/websiteManagement/WebsiteCenters/WorkingHoursModal';
import { removeEmpty } from 'utils/removeEmptyValues';

const WebsiteCenterForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userState, setUserState] = useState('');
  const [selectState, setSelectState] = useState({
    name: userState?.name
  });
  const [selectBranch, setSelectBranch] = useState({
    name: 'Full Service',
    id: 1
  });
  const [selectCenterName, setSelectCenterName] = useState({
    label: '',
    value: ''
  });
  const [centerName, setCenterName] = useState([]);
  const [showHours, setShowHours] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const [imageFile, setImageFile] = useState(null);
  const [centerDetails, setCenterdetails] = useState([]);
  const centerNameOption = centerName?.map((item) => ({
    label: item?.name,
    value: item?.id
  }));
  const branchType = [
    { name: 'Full Service', id: 1 },
    { name: 'Affiliate', id: 2 }
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(
      getSingleBranchAction(selectCenterName.value, (res) => {
        setCenterdetails(res);
        setLoading(false);
      })
    );
  }, [selectCenterName]);
  useEffect(() => {
    setLoading(true);
    dispatch(
      getBranchesAction({}, (res) => {
        setCenterName(res?.branches);
        setLoading(false);
      })
    );
  }, []);

  const handleCreateWebsiteCenter = (values) => {
    const payload = {
      branch_id: selectCenterName.value,
      address: selectCenterName.value ? centerDetails?.address : values.address,
      center_type: handleName(selectBranch?.name),
      name: values.name,
      center_email: selectCenterName.value ? centerDetails?.email : values.email,
      contact_number: selectCenterName.value ? centerDetails?.phone_number : values.contact_number,
      time_open_weekday: values.time_open_weekday,
      time_closed_weekday: values.time_closed_weekday,
      time_open_weekend: values.time_open_weekend,
      time_closed_weekend: values.time_closed_weekend,
      state: selectCenterName.value ? centerDetails?.state : selectState?.name,
      city: selectCenterName.value ? centerDetails?.city : values.city,
      image: imageFile
    };
    setLoading(true);
    dispatch(
      createWebsiteCentersAction(
        removeEmpty(payload),
        () => {
          toast.success('Centre Created');
          navigate('/website-management?type=websitecenters');
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error(err);
        }
      )
    );
  };
  const filteredBranchType =
    selectBranch.name === 'Full Service'
      ? branchType.filter((item) => item.name === 'Affiliate')
      : branchType.filter((item) => item.name === 'Full Service');
  const websiteFormSchema =
    selectBranch.name === 'Full Service' ? fullServiceSchema : affiliateSchema;
  return (
    <div className={styles.mainform}>
      <Formik
        enableReinitialize
        onSubmit={handleCreateWebsiteCenter}
        validationSchema={websiteFormSchema}
        initialValues={{
          name: '',
          address: '',
          center_type: '',
          contact_number: '',
          time_open_weekday: '',
          time_closed_weekday: '',
          time_open_weekend: '',
          time_closed_weekend: '',
          state: '',
          city: ''
        }}
      >
        {(formik) => {
          useEffect(() => {
            formik.resetForm();
            setSelectCenterName({ label: '', value: '' });
            setCenterdetails([]);
            setImageFile(null);
            setDisplayImage(null);
          }, [selectBranch]);
          return (
            <Form>
              <div style={{ width: '400px', margin: '0 auto' }}>
                <div className={styles.mainform__assignSelect}>
                  <p className={styles.mainform__assignSelect__label}>Assign centre type:</p>
                  <div className={styles.mainform__select}>
                    <Select
                      type="text"
                      placeholder="Full Service"
                      options={filteredBranchType.map((item) => ({
                        name: item.name,
                        id: item.id
                      }))}
                      selected={selectBranch}
                      handleChange={setSelectBranch}
                      style={{ height: '29px', width: '150px' }}
                      dropDownStyle={{ top: 'calc(70% + 1px)' }}
                    />
                  </div>
                </div>
                {selectBranch.name === 'Full Service' ? (
                  <>
                    <div className={styles.mainform__input}>
                      <ReactSelect
                        label="Select Center"
                        loading={loading}
                        options={centerNameOption}
                        placeholder="Select center"
                        onChange={(e) => {
                          setSelectCenterName(e);
                          formik.setFieldValue('branch_id', e.value);
                        }}
                      />
                      {selectCenterName.value && (
                        <div className={styles.mainform__address}>
                          {loading ? (
                            'Loading...'
                          ) : (
                            <>
                              <p>{centerDetails?.address}</p>
                              <p>{centerDetails?.phone_number}</p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.mainform__input}>
                      <Input
                        label="Website Display Name"
                        type="text"
                        placeholder="Display Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="name"
                        id="name"
                      />
                      {formik.errors.name ? (
                        <span className="error">{formik.errors.name}</span>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <FormDetails
                    formik={formik}
                    setSelectState={setSelectState}
                    selectState={selectState}
                    states={states}
                  />
                )}

                <div
                  onClick={() => setShowHours(!showHours)}
                  className={styles.workingHoursContainer}
                >
                  <div className={styles.clock}>
                    <img src={Clock} alt="" />
                    <p>Opening hours</p>
                  </div>
                  <div className={styles.workingHours}>
                    <p>
                      Weekdays{' '}
                      {formik.values.time_open_weekday && formik.values.time_closed_weekday ? (
                        <>
                          <span>{formik.values.time_open_weekday}</span>{' '}
                          <span>{formik.values.time_closed_weekday}</span>
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                    <p>
                      Saturdays{' '}
                      {formik.values.time_open_weekend && formik.values.time_closed_weekend ? (
                        <>
                          <span>{formik.values.time_open_weekend}</span>{' '}
                          <span>{formik.values.time_closed_weekend}</span>
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>

                <ImageFile
                  setImageFile={setImageFile}
                  imageDisplayed={setDisplayImage}
                  displayImage={displayImage}
                  setDisplayImage={setDisplayImage}
                />

                <div className={styles.mainform}>
                  <Button
                    theme="primary"
                    size="md"
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty || loading || !imageFile}
                    loading={loading}
                  >
                    Create website centre
                  </Button>
                </div>
                {showHours && <WorkingHoursModal formik={formik} setShowHours={setShowHours} />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WebsiteCenterForm;
